import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TripRequestType from '../../common/trip-request-type/TripRequestType';
import TripDuration from '../../common/trip-duration/TripDuration';
import { Button } from '@mui/material';
import './RegularTripCard.scss';
import { GroupedJourneyItem } from '../../../../models/grouped-journey';
import TripAddresses from '../../common/trip-addresses/TripAddresses';

interface RegularTripCardProps {
  trip: GroupedJourneyItem,
  onTripDetails: (trip: GroupedJourneyItem) => void,
}

const RegularTripCard = ({ trip, onTripDetails }: RegularTripCardProps) => {
  const { t } = useTranslation();
  const [days, setDays] = useState<string[]>([]);

  useEffect(() => {
    const translatedDays = trip.days.map((day) => t(`trips.tripCard.tripDays.${day}`));
    setDays(translatedDays);
  }, [trip, t]);

  return (
    <div className="TripsListItem TripsListItemRegular">
      <div className="TripsListItemHeader">
        <div className="TripsListItemHeaderLeft">
          <TripRequestType requestType={trip.requestType} />
        </div>
        <div className="TripsListItemHeaderRight">
          <strong>{t('trips.tripCard.days')}</strong>
          {days.join(' ')}
        </div>
      </div>
      <div className="TripsListItemBody">
        <TripAddresses pickUp={trip.pickUpAddress} dropOff={trip.dropOffAddress} />
        <TripDuration
          requestStartDate={trip.requestStartDate}
          requestEndDate={trip.requestEndDate}
          frequency={trip.frequency}
        />
      </div>
      <div className="TripsListItemActions">
        <Button
          variant="outlined"
          size="small"
          id={`btn-details-trip-${trip.id}`}
          onClick={() => onTripDetails(trip)}
        >{t('trips.tripCard.details')}</Button>
      </div>
    </div>
  );
};

export default RegularTripCard;