import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import journeyApi from '../../../api/journey';
import Loader from '../../../layout/loader/Loader';
import { JourneyDto, RequestType } from '../../../models/journey';
import { selectCustomer } from '../../../store/customerSlice';
import InformationAlert from '../../alert/information-alert/InformationAlert';
import ServerError from '../../alert/server-error/ServerError';
import ServerSuccess from '../../alert/server-success/ServerSuccess';
import './NextTrips.scss';
import CancelTripDialog from './cancel-trip-dialog/CancelTripDialog';
import NextTripCard from './next-trip-card/NextTripCard';
import NextTripDialog from './next-trip-dialog/NextTripDialog';
import ReactGA from 'react-ga';

const NextTrips = () => {
  const { t, i18n } = useTranslation();
  const [next14DaysTrips, setNext14DaysTrips] = useState<JourneyDto[]>([]);
  const [selectedTrip, setSelectedTrip] = useState<JourneyDto | undefined>();
  const [isLoading, setIsLoading] = useState(true);
  const [hasServerError, setHasServerError] = useState(false);
  const [serverErrorMessage, setServerErrorMessage] = useState('');
  const [hasServerSuccess, setHasServerSuccess] = useState(false);
  const [isDialogOpened, setIsDialogOpened] = useState(false);
  const [isDialogLoading, setIsDialogLoading] = useState(false);
  const [cancelDialogTitle, setCancelDialogTitle] = useState('cancelTrip.defaultTitle');
  const [cancelDialogText, setCancelDialogText] = useState('cancelTrip.defaultText');
  const [canCancelTrip, setCanCancelTrip] = useState(true);
  const [isCancelDialogOpened, setIsCancelDialogOpened] = useState(false);
  const [tripToCancel, setTripToCancel] = useState<JourneyDto>();
  const [canFetchTrips, setCanFetchTrips] = useState(false);
  const customer = useSelector(selectCustomer);
  const cancelRoundTripOptions = ['cancelRoundTrip', 'cancelFirstRoundOnly', 'cancelSecondRoundOnly'];
  const [messageServerSucess, setMessageServerSuccess] = useState('');

  const resetAlerts = () => {
    setHasServerSuccess(false);
    setHasServerError(false);
    setServerErrorMessage('');
  };

  const fetchTrips = (customerId: number, lang: string) => {
    setIsLoading(true);
    journeyApi.getCustomerJourneys(customerId.toString(), 'Dated', 'Active', `${lang}-CA`)
      .then((response) => {
        setNext14DaysTrips(response.data.journeys);
        setIsLoading(false);
        setCanFetchTrips(false);
      })
      .catch((error) => {
        console.error(error);
        setNext14DaysTrips([]);
        setIsLoading(false);
        setHasServerError(true);
        setServerErrorMessage(t('error.tripsServerErrorText'));
      });
  };

  useEffect(() => {
    // Track a custom event
    ReactGA.event({
      category: 'Trip',
      action: 'My trips',
      label: 'Upcoming trips',
    });
    if (customer) {
      resetAlerts();
      fetchTrips(customer.identifier, i18n.language);
    } else {
      setIsLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer, i18n.language]);

  
  useEffect(() => {
    
    if (customer && canFetchTrips) {
      fetchTrips(customer.identifier, i18n.language);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canFetchTrips]);

  const handleTripDetails = (trip: JourneyDto) => {
    setSelectedTrip(trip);
    setIsDialogOpened(true);
  };

  const setModalText = (trip: JourneyDto) => {
    const now = dayjs();
    const pickupDateTime = dayjs(trip.earliestPickUpDateTime);

    if (trip.journeyStatus === 'Confirmed' && pickupDateTime.diff(now, 'minutes') < 60) { // Unconfirmed trip can be cancelled anytime
      setCanCancelTrip(false);
      setCancelDialogTitle('cancelTrip.lateTitle');
      setCancelDialogText('cancelTrip.lateText');
    } else {
      if (trip.requestType === RequestType.Regular) {
        setCancelDialogTitle('cancelTrip.regularTitle');
        setCancelDialogText('cancelTrip.regularText');
      } else {
        setCancelDialogTitle('cancelTrip.defaultTitle');
        setCancelDialogText('cancelTrip.defaultText');
      }
      setCanCancelTrip(true);
    }
  };

  const handleCancelTrip = (trip: JourneyDto) => {
    setModalText(trip);
    setTripToCancel(trip);
    setIsCancelDialogOpened(true);
  };

  const handleCancelTripSubmit = (trip?: JourneyDto, cancelOption?: string) => {
    resetAlerts();
    if (trip && customer) {
      setIsDialogLoading(true);
      if (trip?.returnJourney) {
        if (cancelOption === 'cancelRoundTrip' || cancelOption === 'cancelFirstRoundOnly') {
          callCancelJourney(customer.identifier.toString(), trip, cancelOption);
        }
  
        if (cancelOption === 'cancelSecondRoundOnly') {
          callCancelJourney(customer.identifier.toString(), trip.returnJourney, cancelOption);
        }
      } else {
        callCancelJourney(customer.identifier.toString(), trip, 'one-way');
      }
    } else {
      setIsCancelDialogOpened(false);
    }
  };

  const callCancelJourney = (customerIdentifier: string, trip: JourneyDto, cancelOption?: string ) =>{
    journeyApi.cancelJourney(customerIdentifier, trip.key.scheduleId!, trip.key.journeyId!, `${i18n.language}-CA`)
      .then(() => {
        if (cancelOption === 'cancelRoundTrip' && trip.returnJourney) {
          journeyApi.cancelJourney(customerIdentifier, trip.returnJourney.key.scheduleId!, trip.returnJourney.key.journeyId!, `${i18n.language}-CA`)
            .then(() =>{
              setIsDialogLoading(false);
              setIsCancelDialogOpened(false);
              setHasServerSuccess(true);
              setMessageServerSuccess(t(`cancelTrip.successTitle.${cancelOption}`));
              setCanFetchTrips(true);
            })
            .catch((error) => {
              setIsCancelDialogOpened(false);
              setIsDialogLoading(false);
              setHasServerSuccess(false);
              setHasServerError(true);
              setServerErrorMessage(error?.response?.data?.errors?.[0].message ?? t('error.tripsServerErrorText'));
            });
        } else {
          setIsDialogLoading(false);
          setIsCancelDialogOpened(false);
          setHasServerSuccess(true);
          setMessageServerSuccess(t(`cancelTrip.successTitle.${cancelOption}`));
          setCanFetchTrips(true);
        }
      })
      .catch((error) => {
        setIsCancelDialogOpened(false);
        setIsDialogLoading(false);
        setHasServerSuccess(false);
        setHasServerError(true);
        setServerErrorMessage(error?.response?.data?.errors?.[0].message ?? t('error.tripsServerErrorText'));
      });
  };
  const handleCloseDialog = () => {
    setIsDialogOpened(false);
  };

  const handleCloseCancelTripDialog = () => {
    setIsCancelDialogOpened(false);
    setTripToCancel(undefined);
  };

  const handleCloseSuccesMessage = () => {
    setHasServerSuccess(false);
  };
  
  return (
    <div className="NextTrips">
      { isLoading && <Loader></Loader> }
      { hasServerError && <ServerError>{serverErrorMessage}</ServerError> }
      { hasServerSuccess && <ServerSuccess title={messageServerSucess} onClose={handleCloseSuccesMessage}></ServerSuccess> }
      { (!hasServerError || next14DaysTrips?.length > 0) && <p className="NextTripsIntro">{t('trips.next14DaysTrips')}</p> }
      { ((!next14DaysTrips || next14DaysTrips?.length === 0) && !isLoading && !hasServerError) && <p className="noTrips">{t('trips.noNext14daysTrip')}</p> }
      { next14DaysTrips?.length > 0 && next14DaysTrips.some(x => x.journeyStatus === 'Confirmed') &&  
        <>
          <section className="TripsList">
            { next14DaysTrips.map((trip, index) => (
               <NextTripCard
                key={index}
                trip={trip}
                onTripDetails={handleTripDetails}
                onCancelTrip={handleCancelTrip}
              />             
            ))}
          </section>
          <InformationAlert title={t('cancelTrip.cancellationDelayTitle')}>
            <span dangerouslySetInnerHTML={{ __html: t('cancelTrip.cancellationDelayText') }}></span>
          </InformationAlert>
        </>
      }
      <NextTripDialog
         trip={selectedTrip}
         isOpened={isDialogOpened}
         onCloseDialog={handleCloseDialog}
         customer={customer}
      />
      <CancelTripDialog
        trip={tripToCancel}
        title={cancelDialogTitle}
        text={cancelDialogText}
        isOpened={isCancelDialogOpened}
        isLoading={isDialogLoading}
        cancelRoundTripOptions={cancelRoundTripOptions}
        canCancelTrip={canCancelTrip}
        onCloseDialog={handleCloseCancelTripDialog}
        onCancelTrip={handleCancelTripSubmit}
      />
    </div>
  );
};

export default NextTrips;
