import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { useTranslation } from 'react-i18next';
import { capitalize } from '../../../../utils/text';
import './TripDetailsClientInfo.scss';
import { formatDate } from '../../../utils/utils';

interface TripDetailsClientInfoProps {
  client: string,
  matricule?: number,
  date?: string,
  creationDate?: string,
  phone: string,
  requestStartDate?: string,
  requestEndDate?: string,
  frequency?: number
}

const TripDetailsClientInfo = ({
  client,
  matricule,
  date,
  creationDate,
  phone,
  requestStartDate,
  requestEndDate,
  frequency
}: TripDetailsClientInfoProps) => {
  const { t } = useTranslation();
  dayjs.extend(advancedFormat);
  const formatLongDate = (dateToFormat: string) => {
    return dayjs(dateToFormat).format(t('trips.tripDetails.longDateFormat'));
  };

  return (
    <div className="TripDetailsClientInfo">
      <div>
        <h3 className="TripDetailsClientInfoTitle">{t('trips.tripDetails.client')}</h3>
        <span className="TripDetailsClientInfoValue">{client}</span>
      </div>
      <div>
        <h3 className="TripDetailsClientInfoTitle">{t('trips.tripDetails.matricule')}</h3>
        <span className="TripDetailsClientInfoValue">{matricule ? matricule : ''}</span>
      </div>
      {creationDate && <div>
        <h3 className="TripDetailsClientInfoTitle">{t('trips.tripDetails.creationDate')}</h3>
        <span className="TripDetailsClientInfoValue">{capitalize(formatLongDate(creationDate) )}</span>
      </div>}
      {date && <div>
        <h3 className="TripDetailsClientInfoTitle">{t('trips.tripDetails.date')}</h3>
        <span className="TripDetailsClientInfoValue">{capitalize(formatLongDate(date))}</span>
      </div>}
      <div>
        <h3 className="TripDetailsClientInfoTitle">{t('trips.tripDetails.phone')}</h3>
        <span className="TripDetailsClientInfoValue">{phone}</span>
      </div>
      {requestStartDate && <div>
        <h3 className="TripDetailsClientInfoTitle">{t('trips.tripDetails.duration')}</h3>
        <span className="TripDetailsClientInfoValue">{formatDate(requestStartDate)} {t('trips.duration.to')} {formatDate(requestEndDate ?? '')}</span>
      </div>}
      {frequency && <div>
        <h3 className="TripDetailsClientInfoTitle">{t('trips.tripDetails.frequency')}</h3>
        <span className="TripDetailsClientInfoValue">{frequency === 1 ? t('trips.duration.eachWeek') : t('trips.duration.every', { amount: frequency })}</span>
      </div>}
    </div>
  );
};

export default TripDetailsClientInfo;