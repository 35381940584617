import { SvgIcon, SvgIconProps } from '@mui/material';

const IconAccordionAccessibility = (props: SvgIconProps) =>{
  return (
     <SvgIcon>
        <svg width="45" height="41" viewBox="0 0 45 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="19.329" cy="18.8291" r="13.3031" fill="white" stroke="#222121"/>
            <path d="M9.50894 23.3733L13.4119 12.081C13.42 12.057 13.4357 12.0362 13.4565 12.0216C13.4773 12.0071 13.5022 11.9995 13.5276 12H17.041C17.0663 11.9995 
            17.0912 12.0071 17.112 12.0216C17.1328 12.0362 17.1485 12.057 17.1567 12.081L21.0596 23.3733C21.068 23.3961 21.0705 23.4207 21.067 23.4448C21.0635 23.4689 
            21.054 23.4917 21.0395 23.5112C21.0249 23.5307 21.0058 23.5463 20.9837 23.5566C20.9616 23.5668 20.9373 23.5714 20.913 23.5699H18.0823C18.0569 23.5724 18.0316 
            23.5656 18.011 23.5507C17.9904 23.5357 17.9759 23.5138 17.9704 23.489L17.041 20.6736C17.032 20.6413 17.0129 20.6126 16.9866 20.5918C16.9602 20.571 16.928 20.5591 
            16.8944 20.5579H13.6934C13.6599 20.5591 13.6276 20.571 13.6012 20.5918C13.5749 20.6126 13.5558 20.6413 13.5468 20.6736L12.6174 23.489C12.6119 23.5138 12.5974 
            23.5357 12.5768 23.5507C12.5562 23.5656 12.5309 23.5724 12.5056 23.5699H9.6555C9.6312 23.5714 9.60691 23.5668 9.58483 23.5566C9.56276 23.5463 9.54358 23.5307 
            9.52903 23.5112C9.51449 23.4917 9.50504 23.4689 9.50153 23.4448C9.49802 23.4207 9.50057 23.3961 9.50894 23.3733ZM16.1462 18.429C16.1647 18.4306 16.1832 18.4276 
            16.2002 18.4202C16.2172 18.4129 16.232 18.4014 16.2435 18.3869C16.255 18.3724 16.2627 18.3552 16.2659 18.337C16.2691 18.3188 16.2677 18.3001 16.2619 18.2825L15.3672 
            15.5481C15.3325 15.4324 15.2361 15.4324 15.2013 15.5481L14.3066 18.2825C14.3008 18.3001 14.2994 18.3188 14.3026 18.337C14.3059 18.3552 14.3135 18.3724 14.325 
            18.3869C14.3365 18.4014 14.3514 18.4129 14.3683 18.4202C14.3853 18.4276 14.4039 18.4306 14.4223 18.429H16.1462Z" fill="#222121"/>
            <path d="M21.4609 23.4469L23.9022 16.3892C23.9065 16.3743 23.9156 16.3613 23.9282 16.3522C23.9408 16.3431 23.9561 16.3385 23.9716 16.3391H26.1699C26.1854 16.3385 
            26.2007 16.3431 26.2132 16.3522C26.2258 16.3613 26.235 16.3743 26.2393 16.3892L28.6805 23.4469C28.6857 23.4613 28.6871 23.4767 28.6848 23.4918C28.6825 23.5069 
            28.6765 23.5212 28.6674 23.5334C28.6582 23.5456 28.6462 23.5554 28.6323 23.5618C28.6185 23.5682 28.6032 23.5712 28.588 23.5703H26.8062C26.7901 23.5718 26.7739 
            23.5676 26.7605 23.5584C26.7471 23.5493 26.7374 23.5357 26.7329 23.5201L26.1544 21.7615C26.1488 21.7411 26.1367 21.723 26.1201 21.7098C26.1035 21.6966 26.0831 
            21.6891 26.0619 21.6882H24.0641C24.043 21.6891 24.0226 21.6966 24.0059 21.7098C23.9893 21.723 23.9773 21.7411 23.9716 21.7615L23.3931 23.5201C23.3894 23.5332 
            23.382 23.545 23.3717 23.5539C23.3614 23.5628 23.3487 23.5685 23.3352 23.5703H21.5535C21.5382 23.5712 21.523 23.5682 21.5091 23.5618C21.4953 23.5554 21.4832 
            23.5456 21.4741 23.5334C21.4649 23.5212 21.4589 23.5069 21.4566 23.4918C21.4543 23.4767 21.4558 23.4613 21.4609 23.4469ZM25.6107 20.3615C25.6224 20.3634 
            25.6344 20.362 25.6454 20.3575C25.6564 20.353 25.666 20.3456 25.6731 20.3361C25.6803 20.3266 25.6847 20.3153 25.6859 20.3035C25.6871 20.2917 25.6851 20.2798 
            25.6801 20.269L25.1209 18.5566C25.1209 18.4872 25.0399 18.4872 25.0206 18.5566L24.4614 20.2651C24.4575 20.2759 24.4564 20.2875 24.4582 20.2988C24.4599 20.3102 
            24.4644 20.3209 24.4713 20.33C24.4781 20.3392 24.4872 20.3465 24.4976 20.3513C24.5079 20.3562 24.5194 20.3584 24.5308 20.3577L25.6107 20.3615Z" fill="#222121"/>
            <path d="M21.6698 33.9247C30.0792 32.5597 35.7897 24.6359 34.4247 16.2265C33.0597 7.81716 25.1359 2.10659 16.7265 3.47162C8.31715 4.83666 2.60658 12.7604 3.97162 
            21.1698C5.33665 29.5792 13.2604 35.2897 21.6698 33.9247Z" stroke="#222121" strokeWidth="6" strokeMiterlimit="10"/>
            <path d="M32.8252 30.499L41.0219 37.2963" stroke="#222121" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round"/>
         </svg>
     </SvgIcon>     
  );
};
export default IconAccordionAccessibility;