import { ArrowBack } from '@mui/icons-material';
import { Button } from '@mui/material';
import { AxiosError } from 'axios';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import journeyApi from '../../../api/journey';
import { ApiCallStatus } from '../../../api/shared';
import { ErrorApi } from '../../../models/error-api';
import { selectCustomer } from '../../../store/customerSlice';
import {
  resetPlanTrip,
  selectActiveStep,
  selectActiveStepRoundTrip,
  selectConfirmJourneyCallStatus,
  selectConfirmJourneyErrorMessage,
  selectFormPlanTrip,
  selectJourney,
  selectPlanJourneyCallStatus,
  selectPlanJourneyErrorMessage,
  selectSaveJourneyCallStatus,
  selectSaveJourneyErrorMessage,
  selectSteps,
  setActiveStep,
  setActiveStepRoundTrip,
  setConfirmJourneyCallStatusValue,
  setConfirmJourneyErrorMessage,
  setJourneyValue,
  setPlanJourneyCallStatusValue,
  setPlanJourneyErrorMessage,
  setSaveJourneyCallStatusValue,
  setSaveJourneyErrorMessage,
} from '../../../store/planTripSlice';
import ServerError from '../../alert/server-error/ServerError';
import './PlanTripButtons.scss';
import ReactGA from 'react-ga';
import { useState } from 'react';
import { TripTypeChoice } from '../../../models/form-plan-trip';
import { JourneyDto } from '../../../models/journey';

interface PlanTripButtonsProps {
  handleSubmit: any;
}
const PlanTripButtons = ({
  handleSubmit,
}: PlanTripButtonsProps) => {
  const { t } = useTranslation();
  const activeStep = useSelector(selectActiveStep);
  const activeStepRoundTrip = useSelector(selectActiveStepRoundTrip);
  const steps = useSelector(selectSteps);
  const dispatch = useDispatch();
  const formPlanTrip = useSelector(selectFormPlanTrip);
  const customer = useSelector(selectCustomer);
  const callPlanJourneyStatus = useSelector(selectPlanJourneyCallStatus);
  const callPlanJourneyErrorMessage = useSelector(selectPlanJourneyErrorMessage);
  const callSaveJourneyStatus = useSelector(selectSaveJourneyCallStatus);
  const callSaveJourneyErrorMessage = useSelector(selectSaveJourneyErrorMessage);
  const callConfirmJourneyStatus = useSelector(selectConfirmJourneyCallStatus);
  const callConfirmJourneyErrorMessage = useSelector(selectConfirmJourneyErrorMessage);
  const journey = useSelector(selectJourney);
  const navigate = useNavigate();

  const [backScreenReaderText, setBackScreenReaderText] = useState('');

  const handleMouseEnter = (backText: string) => {
    setBackScreenReaderText(backText);
  };

  const handleMouseLeave = () => {
    setBackScreenReaderText('');
  };

  const callPlanJourney = () => {
    dispatch(setPlanJourneyCallStatusValue(ApiCallStatus.Pending));
    dispatch(setPlanJourneyErrorMessage(''));
    journeyApi
      .planJourneys(
        customer?.identifier ?? 0,
        formPlanTrip,
        `${i18next.language}-CA`
      )
      .then((response) => {
        if (response.data) {
          dispatch(setJourneyValue(response.data?.journeys));
          dispatch(setPlanJourneyCallStatusValue(ApiCallStatus.Success));
          dispatch(setActiveStep(activeStep + 1));
        }
      })
      .catch((error: AxiosError) => {
        dispatch(setPlanJourneyCallStatusValue(ApiCallStatus.Error));
        console.warn(error);
        const errorApi = error.response?.data as ErrorApi;
        dispatch(setPlanJourneyErrorMessage(errorApi.errors[0].message));
      });
  };

  const callSaveJourney = () => {
    dispatch(setSaveJourneyCallStatusValue(ApiCallStatus.Pending));
    dispatch(setSaveJourneyErrorMessage(''));
    dispatch(setConfirmJourneyErrorMessage(''));
    const journeysToSave = [journey![0]];
    if (formPlanTrip.TripType === TripTypeChoice.RoundTrip) journeysToSave.push(journey![1]);
    journeyApi
      .saveJourney(
        customer?.identifier ?? 0,
        journeysToSave,
        `${i18next.language}-CA`
      )
      .then((response) => {
        if (response.data) {
          dispatch(setJourneyValue(response.data?.journeys));
          dispatch(setSaveJourneyCallStatusValue(ApiCallStatus.Success));
          response.data.journeys.map((savedJourney:JourneyDto) => {

            callConfirmJourney(savedJourney.key.scheduleId!, savedJourney.key.journeyId!);
          });
        }
      })
      .catch((error) => {
        dispatch(setSaveJourneyCallStatusValue(ApiCallStatus.Error));
        console.warn(error);
        dispatch(
          setSaveJourneyErrorMessage(
            error.errors?.[0]?.message ?? t('error.unknownErrorText')
          )
        );
      });
  };

  const callConfirmJourney = (scheduleId: string, journeyId: string) => {
    dispatch(setConfirmJourneyCallStatusValue(ApiCallStatus.Pending));
    journeyApi
      .confirmJourney(
        customer?.identifier ?? 0,
        scheduleId,
        journeyId,
        `${i18next.language}-CA`
      )
      .then((response) => {
        if (response.data) {
          dispatch(setConfirmJourneyCallStatusValue(ApiCallStatus.Success));
          dispatch(setActiveStep(activeStep + 1));
        }
      })
      .catch((error) => {
        dispatch(setConfirmJourneyCallStatusValue(ApiCallStatus.Error));
        console.warn(error);
        dispatch(
          setConfirmJourneyErrorMessage(
            error.errors?.[0]?.message ?? t('error.unknownErrorText')
          )
        );
      });
  };

  const handleNextStep = () => {
    if (activeStep <= steps.length &&  isActiveStepValid()) {  
      dispatch(setActiveStep(activeStep + 1));
      ReactGA.event({
        category: 'Trip',
        action: 'Trip planner',
        label: 'Step' + activeStep,
      });
    }
  };

  const handlePrevStep = () => {
    if (activeStep > 0) {
      dispatch(setActiveStep(activeStep - 1));
      switch (activeStep) {
        case 2: 
          dispatch(setPlanJourneyCallStatusValue(ApiCallStatus.Initial));
          dispatch(setPlanJourneyErrorMessage(''));
      }
    }
  };

  const handleCancel = () => {
    dispatch(resetPlanTrip());
    navigate('/');
  };

  const handleSeeMyTrips = () => {
    dispatch(resetPlanTrip());
    navigate('/voir-mes-deplacements');
  };

  const handleReturnMainMenu = () => {
    dispatch(resetPlanTrip());
    navigate('/');
  };
  
  const handleNextStepRoundTrip = () => {
    const isFormValidStep = handleSubmit();
    if (isFormValidStep) {
      dispatch(setActiveStepRoundTrip(activeStepRoundTrip + 1));
    }
  };
  const handlePrevStepRoundTrip = () => {
    dispatch(setActiveStepRoundTrip(activeStepRoundTrip - 1));
  };

  const isActiveStepValid = () => {
    switch (activeStep) {
      //Adresse
      case 0:
        const isFormStep1Valid = handleSubmit();
        return isFormStep1Valid;
      //Date and time
      case 1:
        const isFormValidStep = handleSubmit();
        if (isFormValidStep) {
          callPlanJourney();
        }
        return (
          isFormValidStep &&
          callPlanJourneyStatus === ApiCallStatus.Success
        );
      //Resume and confirmation.
      case 2:
        if (
          journey &&
          journey.length > 0 &&
          callPlanJourneyStatus === ApiCallStatus.Success
        ) {
          callSaveJourney();
        }
        return (
          journey &&
          journey.length > 0 &&
          callSaveJourneyStatus === ApiCallStatus.Success
        );
      default:
        return true;
    }
  };
  return (
    <div>
      {activeStep === 0 && (
        <div className="btnContainer">
          <Button
            id='btn-continue-reservation'
            variant="contained"
            className="btnNextStep"
            onClick={handleNextStep}
          >
            {t('plan-trip.buttons.textBtnNextStep1')}
          </Button>
        </div>
      )}
      {activeStep === 1 && formPlanTrip.TripType === TripTypeChoice.RoundTrip && activeStepRoundTrip === 0 && (
        <>
          {callPlanJourneyStatus === ApiCallStatus.Error && (
            <ServerError>{callPlanJourneyErrorMessage}</ServerError>
          )}
          <div className="btnContainer">
            <Button
              id='btn-back-step-round-trip'
              className="btnBack"
              variant="outlined"
              startIcon={<ArrowBack />}
              onClick={handlePrevStep}
              aria-label={t('plan-trip.buttons.backBtnLabel') + ' ' + activeStep.toString()}
              onMouseEnter={() => handleMouseEnter(t('plan-trip.buttons.backBtnLabel') + ' ' + activeStep.toString())}
              onMouseLeave={handleMouseLeave}
            ></Button>
            <Button
              id='btn-plan-return-trip'
              variant="contained"
              className="btnNextStep"
              onClick={handleNextStepRoundTrip}
            >
              {t('plan-trip.buttons.textBtnNextStep2RoundTrip')}
            </Button>
          </div>
        </>
      )}
      {activeStep === 1 && (formPlanTrip.TripType === TripTypeChoice.OneWay || (formPlanTrip.TripType === TripTypeChoice.RoundTrip && activeStepRoundTrip === 1 )) && (
        <>
          {callPlanJourneyStatus === ApiCallStatus.Error && (
            <ServerError>{callPlanJourneyErrorMessage}</ServerError>
          )}
          <div className="btnContainer">
            <Button
              id='btn-back-one-way'
              className="btnBack"
              variant="outlined"
              startIcon={<ArrowBack />}
              onClick={formPlanTrip.TripType === TripTypeChoice.OneWay ? handlePrevStep : handlePrevStepRoundTrip}
              aria-label={t('plan-trip.buttons.backBtnLabel') + ' ' + activeStep.toString()}
              onMouseEnter={() => handleMouseEnter(t('plan-trip.buttons.backBtnLabel') + ' ' + activeStep.toString())}
              onMouseLeave={handleMouseLeave}
            ></Button>
            <Button
              id='btn-next-step'
              variant="contained"
              className="btnNextStep"
              onClick={handleNextStep}
            >
              {t('plan-trip.buttons.textBtnNextStep2')}
            </Button>
          </div>
        </>
      )}
      {(activeStep === 2 || activeStep === 3) && (
        <>
          {callSaveJourneyStatus === ApiCallStatus.Error && (
            <ServerError>{callSaveJourneyErrorMessage}</ServerError>
          )}
          {callConfirmJourneyStatus === ApiCallStatus.Error && (
            <ServerError>{callConfirmJourneyErrorMessage}</ServerError>
          )}
          {callConfirmJourneyStatus !== ApiCallStatus.Success && (
            <>
              <div className="btnContainer">
                <Button
                  id='btn-back-step-icon'
                  className="btnBack"
                  variant="outlined"
                  startIcon={<ArrowBack />}
                  onClick={handlePrevStep}
                  aria-label={t('plan-trip.buttons.backBtnLabel') + ' ' + activeStep.toString()}
                  onMouseEnter={() => handleMouseEnter(t('plan-trip.buttons.backBtnLabel') + ' ' + activeStep.toString())}
                  onMouseLeave={handleMouseLeave}
                ></Button>
                <Button
                  id='btn-confirm-step'
                  variant="contained"
                  className="btnNextStep"
                  onClick={handleNextStep}
                >
                  {t('plan-trip.buttons.textBtnSubmitStep3')}
                </Button>
              </div>
              <div className="btnCancelContainer">
                <Button
                  id="btn-cancel-step"
                  variant="text"
                  className="btnCancel"
                  onClick={handleCancel}
                >
                  {t('plan-trip.buttons.textBtnCancelStep3')}
                </Button>
              </div>
            </>
          )}
          {callConfirmJourneyStatus === ApiCallStatus.Success && (
            <>
              <div className="btnContainer">
                <Button
                  id='btn-my-trips'
                  className="btnSeeMyTrips"
                  variant="outlined"
                  onClick={handleSeeMyTrips}
                >
                  {t('plan-trip.buttons.seeMyTrips')}
                </Button>
              </div>
              <div className="btnReturnMainMenuContainer">
                <Button
                  id='btn-return-main'
                  className="btnReturnMainMenu"
                  variant="text"
                  startIcon={<ArrowBack />}
                  onClick={handleReturnMainMenu}
                >
                  {t('plan-trip.buttons.returnMainMenu')}
                </Button>
              </div>
            </>
          )}
        </>
      )}
      <div aria-live="polite" className='sr-only'>
          {backScreenReaderText}
      </div>
    </div>
  );
};

export default PlanTripButtons;
