import React from 'react';
import { useTranslation } from 'react-i18next';
import { RequestedTimeType } from '../../../../models/journey';
import  dayjs from 'dayjs';
import './TripTimes.scss';

export interface TripTimesUnconfirmedProps {
  requestedTimeType?: RequestedTimeType,
  requestTime: string,
}

const TripTimesUnconfirmed = ({
  requestedTimeType,
  requestTime,
}: TripTimesUnconfirmedProps) => {
  const { t } = useTranslation();

  const formatTime = (date: string) => {
    return dayjs(date).format(t('trips.times.format'));
  };

  return (
    <div className="TripTimes">
      <div>
        <h4 className="TripTimesTitle">{t(`trips.times.askedType.${requestedTimeType}`)}</h4>
        <span className="TripTimesValue">{formatTime(requestTime)}</span>
      </div>
    </div>
  );
};

export default TripTimesUnconfirmed;