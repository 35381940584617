import { useState } from 'react';
import { JourneyDto } from '../../models/journey';
import dayjs from 'dayjs';
import { t } from 'i18next';

const useSavedTimeoutMaps = () => {
  const [timeoutMaps, setTimeoutMaps] = useState<Map<any, any>[]>([]);

  const addTimeoutMap = (timeoutMap: Map<any, any>) => {
    setTimeoutMaps([...timeoutMaps, timeoutMap]);
  };

  const setNewTimeoutMaps = (newTimeoutMaps: Map<any, any>[]) => {
    setTimeoutMaps(newTimeoutMaps);
  };

  return { timeoutMaps, addTimeoutMap, setNewTimeoutMaps };
};

// This limit the rate of function invocation. It delays invoking the provided function until after a specified delay has passed
// It uses a "key" value passed in params to keep track of which function is currently waiting invocation. If it would be possible to track
// with the provided function directly, then "key" wouldn't be necessary.
export const Debounce = (func: any, delay: any, key: any) => {
  const { timeoutMaps, addTimeoutMap, setNewTimeoutMaps } = useSavedTimeoutMaps();

  return function (...args: any[]) {
    // Create the timeout for the function received in param
    const timeoutId = setTimeout(() => {
      func(...args); // Invoke function

      // Clear the timeoutmaps of the function
      const indexToDelete = timeoutMaps.findIndex(map => map.has(key));
      const newTimeoutMaps = timeoutMaps.filter((item, index) => index !== indexToDelete);
      setNewTimeoutMaps(newTimeoutMaps);
    }, delay);

    // Find the timeout map index that matches the key
    const index = timeoutMaps.findIndex(map => map.has(key));

    // If we have an index, then the timeout mapping is found. Delete the current pending timeout and set the new one
    if (index !== -1) {
      const timeoutMap = timeoutMaps[index];
      timeoutMap.forEach(id => clearTimeout(id));
      timeoutMap.set(key, timeoutId);
    } else { // No mapping is found. Create a new one and add it to the list
      const timeoutMap = new Map();
      timeoutMap.set(key, timeoutId);
      addTimeoutMap(timeoutMap);
    }
  };
};

export const getJourneyUniqueId = (journey: JourneyDto | undefined) => {
  return `${journey?.key.journeyId}-${journey?.key.scheduleId}`;
};

export const formatDate = (date: string) => {
  const formattedDate = dayjs(date).format('YY-MM-DD');
  return formattedDate === '01-01-01' ? t('trips.duration.noEndDate') : formattedDate;
};