import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import IconPlan from '../../icons/IconPlan';
import IconProfil from '../../icons/IconProfil';
import IconTrips from '../../icons/IconTrips';
import CustomerError from '../../layout/customer-error/CustomerError';
import LanguageSwitcher from '../../layout/language-switcher/LanguageSwitcher';
import { selectCustomer, selectHasCustomer } from '../../store/customerSlice';
import './Home.scss';
import { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import MinimizeIcon from '@mui/icons-material/Minimize';
import IconAccordionReservation from '../../icons/IconAccordionReservation';
import IconAccordionAccessibility from '../../icons/IconAccordionAccessibility';

const Home = () => {
  const customer = useSelector(selectCustomer);
  const hasCustomer = useSelector(selectHasCustomer);
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <>
      <LanguageSwitcher />
      <div className="Home container-fluid">
        <CustomerError />
        { hasCustomer && <>
          <div className="Home__intro">
            <h1 className="Home__intro-title-mobile">{t('header.title')}</h1>
            <h2 className="Home__intro-title ">{t('welcome')} {customer?.firstName} {customer?.lastName},</h2>
            <p>{t('what-are-you-planning-to-do-today')}</p>
          </div>
          <div className="Home__cards">
            <Link to={`/${i18n.language}/consulter-mon-profil`} className="Home__cards-item">
              <span className="Home__cards-icon" aria-hidden="true">
                <IconProfil />
              </span>
              <span>{t('view-my-profile')}</span>
              <span className="Home__cards-arrow" aria-hidden="true">
                <ArrowForwardIcon />
              </span>
            </Link>
            <Link to={`/${i18n.language}/planifier-un-trajet`} className="Home__cards-item">
              <span className="Home__cards-icon" aria-hidden="true">
                <IconPlan />
              </span>
              <span>{t('plan-a-trip')}</span>
              <span className="Home__cards-arrow" aria-hidden="true">
                <ArrowForwardIcon />
              </span>
            </Link>
            <Link to={`/${i18n.language}/voir-mes-deplacements`} className="Home__cards-item">
              <span className="Home__cards-icon" aria-hidden="true">
                <IconTrips />
              </span>
              <span>{t('view-my-trips')}</span>
              <span className="Home__cards-arrow" aria-hidden="true">
                <ArrowForwardIcon />
              </span>
            </Link>
          </div>
          <div className='accordions-container'> 
              <h3 className='accordion-info'>{t('accordion.info')}</h3>
              <div className='marginTop'>               
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} className='accordions-item'>
                    <AccordionSummary                 
                      expandIcon={ expanded === 'panel1' ? <MinimizeIcon fontSize='large'/> : <AddIcon fontSize='large' /> }
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >                     
                      <div className='accordionIcon'>
                        <IconAccordionReservation />
                        <Typography> {t('accordion.reservation-title')} </Typography>
                      </div>   
                    </AccordionSummary>
                    <AccordionDetails tabIndex={0}>
                      <Typography className='justify'>
                        <span 
                          dangerouslySetInnerHTML={{ __html: t('accordion.reservation-text') } }
                        />
                      </Typography>
                    </AccordionDetails>
                </Accordion>
              </div>
              <div className='marginTop'>               
                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} className='accordions-item'>
                    <AccordionSummary                 
                      expandIcon={ expanded === 'panel2' ? <MinimizeIcon fontSize='large'/> : <AddIcon fontSize='large' /> }
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      <div className='accordionIcon'>
                        <IconAccordionAccessibility />
                        <Typography> {t('accordion.accessibility-title')}</Typography>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails tabIndex={0}>
                      <Typography className='justify'>
                        <span dangerouslySetInnerHTML={{ __html: t('accordion.accessibility-text.windowsPc') } }/>
                      </Typography><br/>
                      <Typography className='justify'>
                        <span dangerouslySetInnerHTML={{ __html: t('accordion.accessibility-text.macPc') } }/>
                      </Typography><br/>
                      <Typography className='justify'>
                        <span dangerouslySetInnerHTML={{ __html: t('accordion.accessibility-text.minimise-size') } }/>
                      </Typography><br/>
                      <Typography className='justify'>
                        <span dangerouslySetInnerHTML={{ __html: t('accordion.accessibility-text.mobile') } }/>                  
                      </Typography><br/>

                    </AccordionDetails>
                </Accordion> 
              </div>
            </div>
        </> }
      </div>
    </>
  );
};

export default Home;