import React from 'react';
import { useTranslation } from 'react-i18next';
import './RegularTripDialog.scss';
import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog, 
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import TripDetailsClientInfo from '../../common/trip-details-client-info/TripDetailsClientInfo';
import { GroupedJourneyItem } from '../../../../models/grouped-journey';
import { CustomerDto } from '../../../../models/customer';
import TripDetailsAssistance from '../../common/trip-details-assistance/TripDetailsAssistance';
import TripDetailsAddresses from '../../common/trip-details-addresses/TripDetailsAddresses';

interface RegularTripDialogProps {
  trip?: GroupedJourneyItem,
  isOpened: boolean,
  customer?: CustomerDto,
  onCloseDialog: () => void,
}

const RegularTripDialog = ({ trip, isOpened, customer, onCloseDialog }: RegularTripDialogProps) => {
  const { t } = useTranslation();

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      onClose={onCloseDialog}
      aria-labelledby="customized-dialog-title"
      open={isOpened}
      className="RegularTripDialog"
    >
      <DialogTitle id="customized-dialog-title">
        {t(`trips.tripDetails.requestType.${trip?.requestType}${trip?.returnJourney ? 'RoundTrip' : ''}`)}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onCloseDialog}
        sx={{
          position: 'absolute',
          right: 5,
          top: 10,
          color: '#222121',
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers tabIndex={0}>
        {trip && <TripDetailsClientInfo
          client={customer ? `${customer.firstName} ${customer.lastName}` : ''}
          matricule={customer?.identifier}
          creationDate={trip.creationDate}
          phone={trip.contactPhone ?? ''}
          requestStartDate={trip.requestStartDate}
          requestEndDate={trip.requestEndDate}
          frequency={trip.frequency}
        />}
        {trip && <>
          <hr />
          <TripDetailsAddresses trips={trip.trips ?? []} />
        </> }
        {trip && <>
          <hr />
          <TripDetailsAssistance assistiveDevices={trip.assistiveDevices?.filter(x =>
            customer?.assistiveDevices?.some(a => a.assistiveDevice.identifier == x.id && a.assistiveDevice.isPublishable))}
            tripPassengers={trip.tripPassengers} />
        </>}
      </DialogContent>
    </Dialog>
  );
};

export default RegularTripDialog;