import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import './NextTripCard.scss';
import { capitalize } from '../../../../utils/text';
import TripRequestType from '../../common/trip-request-type/TripRequestType';
import TripRoute from '../../common/trip-route/TripRoute';
import TripTimes from '../../common/trip-times/TripTimes';
import TripTimesUnconfirmed from '../../common/trip-times/TripTimesUnconfirmed';
import { Button } from '@mui/material';
import TripRoundDateTime from '../../common/trip-round-date-time/TripRoundDateTime';
import TripRoundUnconfirmedDateTime from '../../common/trip-round-date-time/TripRoundUnconfirmedDateTime';
import { JourneyDto, RequestType } from '../../../../models/journey';
import { getJourneyUniqueId } from '../../../utils/utils';

export interface NextTripCardProps {
  trip: JourneyDto,
  onTripDetails: (trip: JourneyDto) => void,
  onCancelTrip: (trip: JourneyDto) => void
}

const NextTripCard = ({ trip, onTripDetails, onCancelTrip }: NextTripCardProps) => {
  const { t } = useTranslation();
  dayjs.extend(advancedFormat);
  
  return (
    <div className="TripsListItem">
      <div className="TripsListItemHeader">
        <div className="TripsListItemHeaderLeft">
          <TripRequestType requestType={trip.journeyStatus === 'Confirmed' ? trip.requestType : RequestType.Unconfirmed} />
        </div>
        <div className="TripsListItemHeaderRight">          
          <h3>{capitalize(dayjs(trip.effectiveDate).format(t('trips.tripCard.dateFormat')))}</h3>          
        </div>
      </div>
      <div className="TripsListItemBody">
        <TripRoute pickUp={trip.pickUpAddress} dropOff={trip.dropOffAddress} isRoundTrip={!!trip.returnJourney} />
        {!trip.returnJourney && trip.journeyStatus === 'Confirmed' && (
          <TripTimes
          earliestPickUpDateTime={trip.earliestPickUpDateTime}
          latestPickUpDateTime={trip.latestPickUpDateTime}
          latestDropOffDateTime={trip.latestDropOffDateTime}
        />
        )}
        {!trip.returnJourney && trip.journeyStatus !== 'Confirmed' && (
          <TripTimesUnconfirmed
          requestedTimeType={trip.requestedTimeType}
          requestTime={trip.requestedTime}
        />
        )}
        {trip.returnJourney && trip.journeyStatus === 'Confirmed' && (
          <TripRoundDateTime
          earliestPickUpDateTimeStart={trip.earliestPickUpDateTime}
          latestPickUpDateTimeStart={trip.latestPickUpDateTime}
          earliestPickUpDateTimeEnd={ trip.returnJourney.earliestPickUpDateTime}
          latestPickUpDateTimeEnd={trip.returnJourney.latestPickUpDateTime}
        />
        )}
        {trip.returnJourney && trip.journeyStatus !== 'Confirmed' && (
          <TripRoundUnconfirmedDateTime
          requestTypeTrip={trip.requestedTimeType}
          requestTimeTrip={trip.requestedTime}
          requestTypeReturnTrip={trip.returnJourney.requestedTimeType}
          requestTimeReturnTrip={trip.returnJourney.requestedTime}
        />
        )}
      </div>
      <div className="TripsListItemActions TripsListItemActionsNextTrips">
        <Button
          variant="outlined"
          size="small"
          id={`btn-details-trip-${getJourneyUniqueId(trip)}`}
          onClick={() => onTripDetails(trip)}
        >{t('trips.tripCard.details')}</Button>
        <Button
          variant="contained"
          size="small"
          id={`btn-cancel-trip-${getJourneyUniqueId(trip)}`}
          onClick={() => onCancelTrip(trip)}
        >{t('trips.tripCard.cancelTrip')}</Button>
      </div>
    </div>
  );
};

export default NextTripCard;