import React from 'react';
import { useTranslation } from 'react-i18next';
import { JourneyAddressDto } from '../../../../models/journey';
import MergedLabelIconWithLetter from '../../../merged-label-icon-with-letter/MergedLabelIconWithLetter';
import './TripRoute.scss';
import IconRoundTrip from '../../../../icons/IconRoundTrip';

const TripRoute = ({ pickUp, dropOff, isRoundTrip }: { pickUp: JourneyAddressDto, dropOff: JourneyAddressDto, isRoundTrip: boolean }) => {
  const { t } = useTranslation();

  return (
    <div className="TripRoute">
      <div className='mergeIconAndRoundTile'>
        {isRoundTrip && <IconRoundTrip  />}
        <h4 className="TripRouteTitle">{isRoundTrip ? t('trips.route.roundTitle') : t('trips.route.title')}</h4>
      </div>
      <div className="TripRouteItem">
        {!isRoundTrip && (<div className="TripRouteItemIcon">
          <MergedLabelIconWithLetter letter="A" />
        </div>)}
        
        <div className="TripRouteItemDesc">
          <span>{pickUp?.shortFormattedDesc}</span>
        </div>
      </div>
      <div className="TripRouteItem">
        {!isRoundTrip && (<div className="TripRouteItemIcon">
          <MergedLabelIconWithLetter letter="B" />
        </div>)}
        
        <div className="TripRouteItemDesc">
          <span>{dropOff?.shortFormattedDesc}</span>
        </div>
      </div>
    </div>
  );
};

export default TripRoute;