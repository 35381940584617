import { Alert, Box, Typography } from '@mui/material';
import './PlanTripResume.scss';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useTranslation } from 'react-i18next';

type PlanTripResumeProps = {
  origin: string | undefined;
  destination: string | undefined;
};
const PlanTripResume = ({ origin, destination }: PlanTripResumeProps) => {
  const { t } = useTranslation();
  return (
    <Alert
      variant="filled"
      severity="info"
      className="banner-resume"
      icon={false}
    >
      <Box component="div" className="banner-resume-container">
        <div className="bus-icon">
          <DirectionsBusIcon />
        </div>
        <Box component="div" className="origin">
         <Typography sx={{ fontWeight: 700, mr: 1 }} >{t('shared.label.from')}</Typography>
          <span className="banner-address">
            {origin}
          </span>
        </Box>
        <div className="destination-arrow">
          <ArrowForwardIcon />
        </div>
        <Box component="div" className="destination">
        <Typography sx={{ fontWeight: 700, mr: 1 }} >{t('shared.label.to')}</Typography>
          <span className="banner-address">
            {destination}
          </span>
        </Box>
      </Box>
    </Alert>
  );
};

export default PlanTripResume;
