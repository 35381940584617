import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TripAssistiveDeviceJourneyDto } from '../../../../models/device';
import './TripDetailsAssistance.scss';
import { PassengerTypeDto } from '../../../../models/passengerType';
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectCustomer } from '../../../../store/customerSlice';

interface TripDetailsAssistanceProps {
  assistiveDevices?: TripAssistiveDeviceJourneyDto[];
  tripPassengers?: string[];
}

const TripDetailsAssistance = ({ assistiveDevices, tripPassengers }: TripDetailsAssistanceProps) => {
  const { t } = useTranslation();
  const [filteredPassengers, setFilteredPassengers] = useState<string[]>([]);
  const customer = useSelector(selectCustomer);

  useEffect(() => {
    setFilteredPassengers(tripPassengers?.filter((passenger) => passenger !== 'Customer') ?? []);
  }, [tripPassengers]);

  return (
    <div className="TripDetailsAssistance">
      <div>
        <h3 className="TripDetailsAssistanceTitle">{t('trips.tripDetails.youRequested')}</h3>
        {(!assistiveDevices || assistiveDevices.length === 0)
          && <span className="TripDetailsAssistanceValue">{t('trips.tripDetails.noAssistiveDevice')}</span>}
        {(assistiveDevices && assistiveDevices.length > 0) && <>
          <ul className="TripDetailsAssistanceList">
            {customer?.assistiveDevices?.filter(ca=> assistiveDevices.some(a=>a.id == ca.assistiveDevice.identifier)).map(
              (device, index) => <li key={index}><span className="TripDetailsAssistanceValue">{device.assistiveDevice?.publicName}</span></li>)}
          </ul>
        </>}
      </div>
      <div>
        <h3 className="TripDetailsAssistanceTitle">
          {filteredPassengers.length > 0 ? `${t('trips.tripDetails.attendant')} (${filteredPassengers.length})` : t('trips.tripDetails.attendant')}</h3>
        {filteredPassengers.length === 0 && <span className="TripDetailsAssistanceValue">{t('trips.tripDetails.noAttendant')}</span>}
        {filteredPassengers.length > 0
          && (
            <span className="TripDetailsAssistanceValue">
              {filteredPassengers.some(x => x === PassengerTypeDto[PassengerTypeDto.Attendant]) && customer?.attendantMandatory
                && <Typography>{t('trips.tripDetails.numberOfMandatory')}</Typography>}
              {filteredPassengers.some(x => x === PassengerTypeDto[PassengerTypeDto.Attendant]) && !customer?.attendantMandatory
                && <Typography>{t('trips.tripDetails.numberOfAttendant')}</Typography>}
              {filteredPassengers.some(x => x === PassengerTypeDto[PassengerTypeDto.Companion])
                && <Typography>{t('trips.tripDetails.numberOfCompanion')}</Typography>}
              {filteredPassengers.some(x => x === PassengerTypeDto[PassengerTypeDto.Child])
                && <Typography>
                  {t('trips.tripDetails.numberOfChildAttending', { count: filteredPassengers.filter(x => x === PassengerTypeDto[PassengerTypeDto.Child]).length })}</Typography>}
            </span>
          )}
      </div>
    </div>
  );
};

export default TripDetailsAssistance;