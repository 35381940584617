import { Icon } from '@mui/material';
import roundTrip from './files/roundTrip.svg';
import { t } from 'i18next';

const IconRoundTrip = () =>{
  return (
    <div className='displayBlock'>
        <Icon>
            <img src={roundTrip} alt={t('')}/>
        </Icon>
    </div>      
  );
};
export default IconRoundTrip;