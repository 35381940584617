
import React, { useState } from 'react';
import './RadioBoutonGroupComponent.scss';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';

interface RadioBoutonGroupComponentProps {
  defaultValue: any;
  value: any;
  valuesList: Array<any>;
  labelsList: Array<any>;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
}

const RadioBoutonGroupComponent = ({
  defaultValue,
  value,
  valuesList,
  labelsList,
  onChange
}: RadioBoutonGroupComponentProps) => {
  
  return (
    <div style={{ width: '100%' }}>
      <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            className='radio-tuile-container'
            name="row-radio-buttons-group"
            defaultValue={defaultValue}
            onChange={onChange}
            value={value}
          >
            {valuesList.map((item, i) => (
              <FormControlLabel
                key={i}
                value={ item }
                control={<Radio />}
                className="label-radio radio-tuile"
                label={labelsList[i]}
              />
            ))}
          </RadioGroup>
    </div>
  );
};

export default RadioBoutonGroupComponent;
