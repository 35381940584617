import { useTranslation } from 'react-i18next';
import { JourneyTripWithPickUpTime } from '../../../../models/journey';
import './TripDetailsAddresses.scss';
import  dayjs from 'dayjs';

const TripDetailsAddresses = ({ trips }: { trips: JourneyTripWithPickUpTime[] }) => {
  const { t } = useTranslation();

  const formatTime = (date: string) => {
    return dayjs(date).format(t('trips.times.format'));
  };

  return (
    <div className="TripDetailsAddresses">
      <ul className="TripDetailsAddressesList">
        {trips.map((trip, index) =>
          <li key={index}>
            <h3 className="TripDetailsAddressesTitle">
              {t('trips.route.roundTitle {aa}', t(`trips.tripCard.tripDaysFull.${trip.dayType}`))}
            </h3>
            <div className="TripDetailsAddressesTimes">
              <div>
                <h4 className="TripDetailsAddressesTimesTitle">{t('trips.times.pickup')}</h4>
                <span
                  className="TripDetailsAddressesTimesValue"
                  dangerouslySetInnerHTML={{ __html: t('trips.times.pickupTime', { from: formatTime(trip.earliestPickUpDateTime), to: formatTime(trip.latestPickUpDateTime) }) }}
                />
              </div>
              <div>
                <h4 className="TripDetailsAddressesTimesTitle">{t('trips.times.arrivalTimeLabel')}</h4>
                <span className="TripDetailsAddressesTimesValue">{formatTime(trip.latestDropOffDateTime)}</span>
              </div>
            </div>
            <div className="TripDetailsAddressesItem">
              <span>
                <strong>{t('trips.tripDetails.from')}</strong>
                {trip.trip.pickUpAddress.longFormattedDesc}
              </span>
            </div>
            <div className="TripDetailsAddressesItem">
              <span>
                <strong>{t('trips.tripDetails.to')}</strong>
                {trip.trip.dropOffAddress.longFormattedDesc}
              </span>
            </div>
            {/* return step */}
            { trip.returnJourney && 
              <div className='TripDetailsReturnJourney'>
                <div className="TripDetailsAddressesTimes">
                <div>
                  <h4 className="TripDetailsAddressesTimesTitle">{t('trips.times.pickup')}</h4>
                  <span
                    className="TripDetailsAddressesTimesValue"
                    dangerouslySetInnerHTML={{ __html: t('trips.times.pickupTime', { from: formatTime(trip.returnJourney.earliestPickUpDateTime), 
                      to: formatTime(trip.returnJourney.latestPickUpDateTime) }) }}
                  />
                </div>
                <div>
                  <h4 className="TripDetailsAddressesTimesTitle">{t('trips.times.arrivalTimeLabel')}</h4>
                  <span className="TripDetailsAddressesTimesValue">{formatTime(trip.returnJourney.latestDropOffDateTime)}</span>
                </div>
              </div>
              <div className="TripDetailsAddressesItem">
                <span>
                  <strong>{t('trips.tripDetails.from')}</strong>
                  {trip.returnJourney.pickUpAddress.longFormattedDesc}
                </span>
              </div>
              <div className="TripDetailsAddressesItem">
                <span>
                  <strong>{t('trips.tripDetails.to')}</strong>
                  {trip.returnJourney.dropOffAddress.longFormattedDesc}
                </span>
              </div>
            </div>
            }
          </li>,
        )}
      </ul>
    </div>
  );
};

export default TripDetailsAddresses;