import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import './TripRoundDateTime.scss';
import { Divider, Grid } from '@mui/material';


export interface TripRoundTimesProps {
  earliestPickUpDateTimeStart: string,
  latestPickUpDateTimeStart: string,
  earliestPickUpDateTimeEnd: string,
  latestPickUpDateTimeEnd: string
}

const TripRoundDateTime = ({
  earliestPickUpDateTimeStart,
  latestPickUpDateTimeStart,
  earliestPickUpDateTimeEnd,
  latestPickUpDateTimeEnd,
}: TripRoundTimesProps) => {
  const { t } = useTranslation();

  const formatTime = (date: string) => {
    return dayjs(date).format(t('trips.times.format'));
  };

  return (
        <div className="TripRoundTimes">
            <Grid container spacing={0} className='flex-nowrap'>
                <Grid item>
                    <Grid container spacing={0} direction={'column'}>
                        <Grid item>                       
                            <h4 className="TripTimesTitle">{t('trips.times.tripStart')}</h4>
                        </Grid>                      
                        <Grid item>
                            <h4 className="TripTimesTitle">{t('trips.times.pickup')}</h4>
                        </Grid>
                        <Grid item>
                            <span
                                className="TripTimesValue"
                                dangerouslySetInnerHTML={{ __html: t('trips.times.pickupTime', 
                                  { from: formatTime(earliestPickUpDateTimeStart), to: formatTime(latestPickUpDateTimeStart) }) }}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Divider orientation="vertical" variant="middle" flexItem className='dividerVertical' />
                <Grid item>
                    <Grid container spacing={0} direction={'column'}>
                        <Grid item>
                            <h4 className="TripTimesTitle">{t('trips.times.tripEnd')}</h4>
                        </Grid>                       
                        <Grid item>
                            <h4 className="TripTimesTitle">{t('trips.times.pickup')}</h4>
                        </Grid>
                        <Grid item>
                            <span
                                className="TripTimesValue"
                                dangerouslySetInnerHTML={{ __html: t('trips.times.pickupTime', 
                                  { from: formatTime(earliestPickUpDateTimeEnd), to: formatTime(latestPickUpDateTimeEnd) }) }}/>
                        </Grid>                       
                    </Grid>                   
                </Grid>
            </Grid>         
        </div>
  );
};

export default TripRoundDateTime;