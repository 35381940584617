import { AddressDto } from './address';

export interface FormPlanTrip {
  PointA: AddressDto | null;
  PointB: AddressDto | null;
  Date: string;
  Time: string;
  ReturnDate: string;
  ReturnTime: string;
  HourType: HourTypeChoice;
  ReturnHourType: HourTypeChoice;
  TripType: TripTypeChoice;
  AssistiveDevices: Array<string>;
  TripPassengers: Array<string>;
  isError: boolean;
}

export enum HourTypeChoice {
  Arrival = 'Arrival',
  Departure = 'Departure',
}
export enum TripTypeChoice {
  RoundTrip = 'RoundTrip',
  OneWay = 'OneWay',
}
