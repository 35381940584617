import { useTranslation } from 'react-i18next';
import './NextTripDialog.scss';
import { JourneyDto } from '../../../../models/journey';
import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import TripDetailsClientInfo from '../../common/trip-details-client-info/TripDetailsClientInfo';
import { CustomerDto } from '../../../../models/customer';
import TripDetailsRoute from '../../common/trip-details-route/TripDetailsRoute';
import TripDetailsUnconfirmedRoute from '../../common/trip-details-route/TripDetailsUnconfirmedRoute';
import TripDetailsAssistance from '../../common/trip-details-assistance/TripDetailsAssistance';

interface NextTripDialogProps {
  trip?: JourneyDto,
  isOpened: boolean,
  customer?: CustomerDto,
  onCloseDialog: () => void,
}

const NextTripDialog = ({ trip, isOpened, customer, onCloseDialog }: NextTripDialogProps) => {
  const { t } = useTranslation();

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      onClose={onCloseDialog}
      aria-labelledby="customized-dialog-title"
      open={isOpened}
      className="NextTripDialog"
    >
      <DialogTitle id="customized-dialog-title">
        {t(`trips.tripDetails.requestType.${trip?.requestType}${trip?.returnJourney ? 'RoundTrip' : ''}`)}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onCloseDialog}
        sx={{
          position: 'absolute',
          right: 5,
          top: 10,
          color: '#222121',
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers tabIndex={0}>
        <>
          {trip && <TripDetailsClientInfo
            client={customer ? `${customer.firstName} ${customer.lastName}` : ''}
            matricule={customer?.identifier}
            date={trip.effectiveDate}
            phone={trip.contactPhone!}
          />}
          {trip && trip.journeyStatus === 'Confirmed' && <>
            <hr />
            <TripDetailsRoute
              title={trip.returnJourney ? t('trips.route.titleStepDepart') : t('trips.route.title')}
              pickUp={trip.pickUpAddress}
              dropOff={trip.dropOffAddress}
              earliestPickUpDateTime={trip.earliestPickUpDateTime}
              latestPickUpDateTime={trip.latestPickUpDateTime}
              latestDropOffDateTime={trip.latestDropOffDateTime}
            />
          </>}
          {trip && trip.journeyStatus !== 'Confirmed' && <>
            <hr />
            <TripDetailsUnconfirmedRoute
              title={trip.returnJourney ? t('trips.route.titleStepDepart') : t('trips.route.title')}
              pickUp={trip.pickUpAddress}
              dropOff={trip.dropOffAddress}
              requestedTimeType={trip.requestedTimeType}
              requestTime={trip.requestedTime}
            />
          </>}
          {trip && trip.returnJourney && trip.returnJourney.journeyStatus === 'Confirmed' && <>
            <hr />
            <TripDetailsRoute
              title = {t('trips.route.titleStepReturn')}
              pickUp={trip.returnJourney.pickUpAddress}
              dropOff={trip.returnJourney.dropOffAddress}
              earliestPickUpDateTime={trip.returnJourney.earliestPickUpDateTime}
              latestPickUpDateTime={trip.returnJourney.latestPickUpDateTime}
              latestDropOffDateTime={trip.returnJourney.latestDropOffDateTime}
            />
          </>}
          {trip && trip.returnJourney && trip.returnJourney.journeyStatus !== 'Confirmed' && <>
            <hr />
            <TripDetailsUnconfirmedRoute
              title = {t('trips.route.titleStepReturn')}
              pickUp={trip.returnJourney.pickUpAddress}
              dropOff={trip.returnJourney.dropOffAddress}
              requestedTimeType={trip.returnJourney.requestedTimeType}
              requestTime={trip.returnJourney.requestedTime}
            />
          </>}
          {trip && <>
            <hr />
            <TripDetailsAssistance assistiveDevices={trip.assistiveDevices?.filter(x =>
              customer?.assistiveDevices?.some(a => a.assistiveDevice.identifier == x.id && a.assistiveDevice.isPublishable))}
              tripPassengers={trip.tripPassengers} />
          </>}
        </>
      </DialogContent>
    </Dialog>
  );
};

export default NextTripDialog;