import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Loader from '../../../../layout/loader/Loader';
import { CustomerDto } from '../../../../models/customer';
import { JourneyDto } from '../../../../models/journey';
import './CancelTripDialog.scss';
import { getJourneyUniqueId } from '../../../utils/utils';
import { ChangeEvent, useState } from 'react';


interface CancelTripDialogProps {
  trip?: JourneyDto,
  title: string,
  text: string,
  isOpened: boolean,
  isLoading: boolean,
  canCancelTrip: boolean,
  customer?: CustomerDto,
  cancelRoundTripOptions: string[],
  onCloseDialog: () => void,
  onCancelTrip: (trip?: JourneyDto, cancelOption?: string) => void
}

const CancelTripDialog = ({ trip, isOpened, isLoading, canCancelTrip, cancelRoundTripOptions, onCloseDialog, onCancelTrip, title, text }: CancelTripDialogProps) => {
  const { t } = useTranslation();
  
  const [selectedCancelRoundTripOption, setSelectedCancelRoundTripOption] = useState('cancelRoundTrip');

  function handleCancelRoundTripOptions(
    event: ChangeEvent<HTMLInputElement>,
  ): void {
    setSelectedCancelRoundTripOption(cancelRoundTripOptions[+event.target.value - 1]);
  }

  const cancelTrip = () => {   
    onCancelTrip(trip, selectedCancelRoundTripOption);    
  };


  return (
    <Dialog
      fullWidth={true}
      maxWidth="xs"
      onClose={onCloseDialog}
      aria-labelledby="customized-dialog-title"
      open={isOpened}
      className="CancelTripDialog"
    >
      <DialogTitle id="customized-dialog-title">
        {t(title)}
      </DialogTitle>
      <IconButton
        aria-label={t('cancelTrip.close')}
        onClick={onCloseDialog}
        sx={{
          position: 'absolute',
          right: 5,
          top: 10,
          color: '#222121',
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers tabIndex={0}>
        { isLoading && <Loader></Loader> }
        <div dangerouslySetInnerHTML={{ __html: t(text) }}></div>
      </DialogContent>
      <DialogActions className='displayBlock'>
        {canCancelTrip && trip?.returnJourney && (<div>
          <RadioGroup name='numberOfChildAllowedRadioGroup'
            defaultValue={1}>
            {cancelRoundTripOptions.map((item, index) => (
              <FormControlLabel
                key={index + 1}
                value={index + 1}
                label={t('cancelTrip.' + item)}
                id={item + index}
                control={
                  <Radio
                    onChange={handleCancelRoundTripOptions}
                    id={(index + 1) + ''}
                  />
                }
              ></FormControlLabel>
            ))}
          </RadioGroup>
        </div>    
        )}           
        {canCancelTrip && <div className='displayEnd'>
          <Button onClick={() => onCloseDialog()} id={`btn-back-${getJourneyUniqueId(trip)}`}>
            {t('cancelTrip.back')}
          </Button>
          <Button variant="contained" onClick={() => cancelTrip()} id={`btn-confirm-cancel-trip-${getJourneyUniqueId(trip)}`}>
            {t('cancelTrip.submit')}
          </Button>
        </div>}
        {!canCancelTrip && <div className='displayEnd'>
        </div>}
        {!canCancelTrip && <div className='displayEnd'>
          <Button variant="contained" onClick={() => onCloseDialog()}>
            {t('cancelTrip.close')}
          </Button>
        </div>}
      </DialogActions>
    </Dialog>
  );
};

export default CancelTripDialog;