import { SvgIcon, SvgIconProps } from '@mui/material';

const IconAccordionReservation = (props: SvgIconProps) =>{
  return (
    <SvgIcon  {...props} inheritViewBox >
        <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
        <ellipse cx="22.0027" cy="19.8212" rx="14.7444" ry="13.9915" fill="white"/>
        <path d="M23.3754 19.5696V13.9285C23.3754 13.4943 23.1936 13.0778 22.8701 12.7708C22.5465 12.4638 22.1077 12.2913 21.6501 12.2913C21.1925 12.2913 
        20.7537 12.4638 20.4301 12.7708C20.1066 13.0778 19.9248 13.4943 19.9248 13.9285V20.2482C19.9313 20.6832 20.1137 21.0993 20.4343 21.4103L24.9755 
        25.7111C25.134 25.8645 25.3232 25.9863 25.5319 26.0694C25.7407 26.1524 25.9649 26.195 26.1913 26.1946C26.532 26.1947 26.8652 26.0991 27.1487 
        25.9197C27.4323 25.7404 27.6535 25.4853 27.7845 25.1868C27.9155 24.8883 27.9504 24.5597 27.8848 24.2423C27.8192 23.925 27.6561 23.6332 27.4159 
        23.4038L23.3754 19.5696Z" fill="#222121"/>
        <path d="M21.6951 0C17.5031 0 13.4053 1.17959 9.91974 3.38962C6.43423 5.59964 3.7176 8.74083 2.11339 12.416C0.509184 16.0911 0.0894498 20.1351 
        0.907267 24.0366C1.72508 27.9381 3.74372 31.5219 6.70791 34.3347C9.6721 37.1475 13.4487 39.0631 17.5601 39.8391C21.6716 40.6152 25.9332 40.2169 
        29.8061 38.6946C33.679 37.1723 36.9892 34.5944 39.3182 31.2869C41.6471 27.9793 42.8902 24.0907 42.8902 20.1128C42.8831 14.7806 40.6478 9.66876 
        36.6745 5.89834C32.7012 2.12792 27.3142 0.00673397 21.6951 0ZM21.6951 32.7352C19.0647 32.7336 16.4938 31.9918 14.3075 30.6039C12.1212 29.2159 
        10.4177 27.2439 9.41232 24.9373C8.40692 22.6307 8.14478 20.093 8.65904 17.6451C9.1733 15.1971 10.4409 12.9488 12.3015 11.1844C14.1621 9.41994 
        16.5323 8.21861 19.1123 7.73226C21.6923 7.24591 24.3664 7.49637 26.7964 8.45198C29.2265 9.40759 31.3034 11.0254 32.7646 13.101C34.2258 15.1766 
        35.0057 17.6167 35.0057 20.1128C35.0034 23.4613 33.6 26.6718 31.104 29.0387C28.608 31.4057 25.2238 32.7352 21.6951 32.7352Z" fill="#222121"/>
        </svg>
    </SvgIcon> 
  );
};
export default IconAccordionReservation;