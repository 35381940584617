import { Box, Button, Divider, Stack, Typography, styled } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import utc from 'dayjs/plugin/utc';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ApiCallStatus } from '../../../api/shared';
import {
  selectConfirmJourneyCallStatus,
  selectFormPlanTrip,
  selectJourney,
  setActiveStep,
  setActiveStepRoundTrip,
  setPlanJourneyCallStatusValue,
  setPlanJourneyErrorMessage,
} from '../../../store/planTripSlice';
import PlanTripButtons from '../plan-trip-buttons/PlanTripButtons';
import PlanTripSuccess from '../plan-trip-success/PlanTripSuccess';
import './PlanTripStep3.scss';
import TagManager from 'react-gtm-module';
import PlanTripBanner from '../plan-trip-banner/PlanTripBanner';
import { Info } from '@mui/icons-material';
import { JourneyDto, RequestedTimeType } from '../../../models/journey';
import { HourTypeChoice, TripTypeChoice } from '../../../models/form-plan-trip';
import { selectCustomer } from '../../../store/customerSlice';
import { PassengerTypeDto } from '../../../models/passengerType';
import { useState } from 'react';
TagManager.dataLayer({
  dataLayer: {
    event: 'planTrip',
    step: 3,
  }
});

const PlanTripStep3 = () => {
  dayjs.extend(isBetween);
  dayjs.extend(utc);
  dayjs.extend(advancedFormat);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const planJourney = useSelector(selectJourney);
  const effectiveDate = dayjs(planJourney![0].effectiveDate);
  const effectiveDateReturn = (planJourney![1] == null ? dayjs() : dayjs(planJourney![1].effectiveDate));
  const callConfirmJourneyStatus = useSelector(selectConfirmJourneyCallStatus);
  const formPlanTrip = useSelector(selectFormPlanTrip);
  const formRequestedDateTime = dayjs(formPlanTrip.Date).set('hour', dayjs(formPlanTrip.Time).hour()).set('minute', dayjs(formPlanTrip.Time).minute());
  const earliestDropOffDateTimeUtc = dayjs(planJourney![0].earliestDropOffDateTime).utc();
  const latestDropOffDateTimeUtc = dayjs(planJourney![0].latestDropOffDateTime).utc();
  const earliestPickUpDateTimeUtc = dayjs(planJourney![0].earliestPickUpDateTime).utc();
  const latestPickUpDateTimeUtc = dayjs(planJourney![0].latestPickUpDateTime).utc();
  const arriveBetween = dayjs(formRequestedDateTime).isBetween(earliestDropOffDateTimeUtc, latestDropOffDateTimeUtc, 'minute', '[]');
  const departBetween = dayjs(formRequestedDateTime).isBetween(earliestPickUpDateTimeUtc, latestPickUpDateTimeUtc, 'minute', '[]');
  const showBannerTimeContrains = planJourney![0].requestedTimeType == RequestedTimeType.Arrival ? !arriveBetween : !departBetween;

  function isTimeContrainsJourney(journey: JourneyDto, date: string, time: string): boolean {
    const formatRequestedDateTime = getFormatedDateTime(date, time);

    if (journey.requestedTimeType == RequestedTimeType.Arrival) {
      return !getDateBetween(formatRequestedDateTime, dayjs(journey.earliestDropOffDateTime).utc(), dayjs(journey.latestDropOffDateTime).utc());
    } else {
      return !getDateBetween(formatRequestedDateTime, dayjs(journey.earliestPickUpDateTime).utc(), dayjs(journey.latestPickUpDateTime).utc());
    }
  }

  function getDateBetween(formatRequestedDateTime: Dayjs, start: Dayjs, end: Dayjs) {
    return dayjs(formatRequestedDateTime).isBetween(start, end, 'minute', '[]');
  }

  function getFormatedDateTime(date: string, time: string) {
    return dayjs(date).set('hour', dayjs(time).hour()).set('minute', dayjs(time).minute());
  }

  const [backScreenReaderText, setBackScreenReaderText] = useState('');

  const Item = styled(Box)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'start',
    color: theme.palette.text.primary,
  }));

  const handleSubmitForm = (): boolean => { return true; };

  const handleBackToTrip = (roundTripStep: number) => {
    // In case the number sent is invalid, we just go back to the depart trip for the round trip process
    if (roundTripStep < 0 || roundTripStep >= 2)
      roundTripStep = 0;

    dispatch(setActiveStepRoundTrip(roundTripStep));
    dispatch(setActiveStep(1));
    dispatch(setPlanJourneyCallStatusValue(ApiCallStatus.Initial));
    dispatch(setPlanJourneyErrorMessage(''));
  };

  const handleMouseEnter = (backText: string) => {
    setBackScreenReaderText(backText);
  };

  const handleMouseLeave = () => {
    setBackScreenReaderText('');
  };

  const customer = useSelector(selectCustomer);

  return (
    <div className="PlanTripStep3" >
      {formPlanTrip.TripType === TripTypeChoice.OneWay && isTimeContrainsJourney(planJourney![0], formPlanTrip.Date, formPlanTrip.Time) && (
        <PlanTripBanner
          level='warning'
          title={t('plan-trip.step3.timeChangetitle')}
          text={formPlanTrip.HourType == HourTypeChoice.Arrival ?
            t('plan-trip.step3.timeChangeArrivalText', { time: formRequestedDateTime.format('LT'), newtime: dayjs(planJourney![0].requestedTime).format('LT') }) :
            t('plan-trip.step3.timeChangeBoardingText', { time: formRequestedDateTime.format('LT'), newtime: dayjs(planJourney![0].requestedTime).format('LT') })
          }
          closeButton={false} showIcon={<Info></Info>}></PlanTripBanner>
      )}
      {callConfirmJourneyStatus === ApiCallStatus.Success && (
        <>
          <PlanTripSuccess></PlanTripSuccess>
          <h2 className="yourReservation">
            {t('plan-trip.step2.yourReservation')}
          </h2>
        </>
      )}
      {callConfirmJourneyStatus !== ApiCallStatus.Success && (
        <>
          <Typography variant="h1" component="h1" className="title-step">
            {formPlanTrip.TripType === TripTypeChoice.OneWay ? t('plan-trip.step3.title') : t('plan-trip.step3.titleRoundTrip')}
          </Typography>
          <Typography
            variant="subtitle1"
            component="h3"
            className="sub-title-step"
          >
            {t('plan-trip.step3.stepDescription')}
          </Typography>
        </>
      )}
      <div>
      {formPlanTrip.TripType === TripTypeChoice.RoundTrip && isTimeContrainsJourney(planJourney![0], formPlanTrip.Date, formPlanTrip.Time) && (
        <PlanTripBanner
          level='warning'
          title={t('plan-trip.step3.timeChangetitle')}
          text={formPlanTrip.HourType == HourTypeChoice.Arrival ?
            t('plan-trip.step3.timeChangeArrivalText', { time: formRequestedDateTime.format('LT'), newtime: dayjs(planJourney![0].requestedTime).format('LT') }) :
            t('plan-trip.step3.timeChangeBoardingText', { time: formRequestedDateTime.format('LT'), newtime: dayjs(planJourney![0].requestedTime).format('LT') })
          }
          closeButton={false} showIcon={<Info></Info>}></PlanTripBanner>
      )}
      <div className='subTitleStepContainer' >
        <Typography variant="h3" component="h3" className="subtitle-step">
          {formPlanTrip.TripType === TripTypeChoice.OneWay ? t('plan-trip.step3.yourTrip') : t('plan-trip.step3.stepDepartTitle')}
        </Typography>
        {callConfirmJourneyStatus !== ApiCallStatus.Success &&
          <Button
              id='btn-modify-depart-trip'
              className="btnModify"
              variant="outlined"
              onClick={() => handleBackToTrip(0)}
              aria-label={formPlanTrip.TripType === TripTypeChoice.OneWay ? t('plan-trip.step3.btnModifyTripAria') : t('plan-trip.step3.btnModifyDepartAria')}
              onMouseEnter={() =>
                handleMouseEnter(formPlanTrip.TripType === TripTypeChoice.OneWay ? t('plan-trip.step3.btnModifyTripAria') : t('plan-trip.step3.btnModifyDepartAria'))}
              onMouseLeave={handleMouseLeave}
            >
              {t('plan-trip.step2.roundTripDepartResumeButton')}
          </Button>
        }
      </div>
        <Stack spacing={2}>
          <Item tabIndex={0}>
            <Typography component="div">
              <strong>{t('plan-trip.step3.fromLabel')}</strong>
              {planJourney![0].pickUpAddress.longFormattedDesc}
            </Typography>
          </Item>
          <Item tabIndex={0}>
            <Typography component="div" >
              <strong>{t('plan-trip.step3.toLabel')}</strong>
              {planJourney![0].dropOffAddress.longFormattedDesc}
            </Typography>
          </Item>
          <Item tabIndex={0}>
            <Typography className="label">
              {t('plan-trip.step3.dateLabel')}
            </Typography>
            <div className='text'>
              <Typography className="capitalize">
                {t('plan-trip.step3.dateDayText', {
                  dayName: effectiveDate.format('dddd')
                })}
              </Typography>
              <Typography className="">
                {t('plan-trip.step3.dateMonthYearText', {
                  dayNumber: effectiveDate.format('Do'),
                  month: effectiveDate.format('MMMM'),
                  year: effectiveDate.year(),
                })}
              </Typography>
            </div>
          </Item>

          <Item tabIndex={0}>
            <Typography className="label">
              {t('plan-trip.step3.boardingTimeLabel')}
            </Typography>
            <Typography className={'text ' + showBannerTimeContrains ? 'aa' : 'bb'}>
              {t('plan-trip.step3.boardingTimeText', {
                startTime: dayjs(planJourney![0].earliestPickUpDateTime).format(
                  'LT'
                ),
                endTime: dayjs(planJourney![0].latestPickUpDateTime).format(
                  'LT'
                ),
              })}
            </Typography>
          </Item>

          <Item tabIndex={0}>
            <Typography className="label">
              {t('plan-trip.step3.arrivalTimeLabel')}
            </Typography>
            <Typography className="text">
              {dayjs(planJourney![0].latestDropOffDateTime).format('LT')}
            </Typography>
          </Item>
          {/* Étape Retour */}
          {formPlanTrip.TripType === TripTypeChoice.RoundTrip && planJourney![1] && (
            <>
              <Divider component="div" />
              <div className='subTitleStepContainer' >
                <Typography variant="h3" component="h3" className="subtitle-step">
                  {t('plan-trip.step3.stepReturnTitle')}
                </Typography>
                {callConfirmJourneyStatus !== ApiCallStatus.Success && 
                  <Button
                    id='btn-modify-return-trip'
                    className="btnModify"
                    variant="outlined"
                    onClick={() => handleBackToTrip(1)}
                    aria-label={t('plan-trip.step3.btnModifyReturnAria')}
                    onMouseEnter={() => handleMouseEnter(t('plan-trip.step3.btnModifyReturnAria'))}
                    onMouseLeave={handleMouseLeave}
                  >
                    {t('plan-trip.step2.roundTripDepartResumeButton')}
                  </Button>
                }
              </div>
              {isTimeContrainsJourney(planJourney![1], formPlanTrip.ReturnDate, formPlanTrip.ReturnTime) && (
                <PlanTripBanner
                  level='warning'
                  title={t('plan-trip.step3.timeChangetitle')}
                  text={formPlanTrip.ReturnHourType == HourTypeChoice.Arrival ?
                    t('plan-trip.step3.timeChangeArrivalText', { time: getFormatedDateTime(formPlanTrip.ReturnDate, formPlanTrip.ReturnTime).format('LT'), 
                      newtime: dayjs(planJourney![1].requestedTime).format('LT') }) :
                    t('plan-trip.step3.timeChangeBoardingText', { time: getFormatedDateTime(formPlanTrip.ReturnDate, formPlanTrip.ReturnTime).format('LT'), 
                      newtime: dayjs(planJourney![1].requestedTime).format('LT') })
                  }
                  closeButton={false} showIcon={<Info></Info>}></PlanTripBanner>
              )}
              <Item tabIndex={0} className='noMarginTop'>
                <Typography component="div" >
                  <strong>{t('plan-trip.step3.fromLabel')}</strong>
                  {planJourney![1].pickUpAddress.longFormattedDesc}
                </Typography>
              </Item>
              <Item tabIndex={0}>
                <Typography component="div">
                  <strong>{t('plan-trip.step3.toLabel')}</strong>
                  {planJourney![1].dropOffAddress.longFormattedDesc}
                </Typography>
              </Item>
              <Item tabIndex={0}>
                <Typography className="label">
                  {t('plan-trip.step3.dateLabel')}
                </Typography>
                <div className='text'>
                  <Typography className="capitalize">
                    {t('plan-trip.step3.dateDayText', { dayName: dayjs(effectiveDateReturn).format('dddd') })}
                  </Typography>
                  <Typography>
                    {t('plan-trip.step3.dateMonthYearText', {
                      dayNumber: dayjs(effectiveDateReturn).format('Do'),
                      month: dayjs(effectiveDateReturn).format('MMMM'),
                      year: dayjs(effectiveDateReturn).year(),
                    })}
                  </Typography>
                </div>
              </Item>

              <Item tabIndex={0}>
                <Typography className="label">
                  {t('plan-trip.step3.boardingTimeLabel')}
                </Typography>
                <Typography className={'text ' + showBannerTimeContrains ? 'aa' : 'bb'}>
                  {t('plan-trip.step3.boardingTimeText', {
                    startTime: dayjs(planJourney![1].earliestPickUpDateTime).format(
                      'LT'
                    ),
                    endTime: dayjs(planJourney![1].latestPickUpDateTime).format(
                      'LT'
                    ),
                  })}
                </Typography>
              </Item>

              <Item tabIndex={0}>
                <Typography className="label">
                  {t('plan-trip.step3.arrivalTimeLabel')}
                </Typography>
                <Typography className="text">
                  {dayjs(planJourney![1].latestDropOffDateTime).format('LT')}
                </Typography>
              </Item>

              <Divider component="div" />
            </>
          )}
          {planJourney![0].tripPassengers
            && planJourney![0].tripPassengers.filter(x => x !== PassengerTypeDto[PassengerTypeDto.Customer]).length > 0
            && (
              <Item tabIndex={0}>
                <Typography className="label">
                  {`${t('plan-trip.step3.attendantLabel')} (${planJourney![0].tripPassengers.filter(x => x !== PassengerTypeDto[PassengerTypeDto.Customer]).length})`}
                </Typography>
                {planJourney![0].tripPassengers.some(x => x === PassengerTypeDto[PassengerTypeDto.Attendant] && customer?.attendantMandatory)
                  && (
                    <Typography className="text">
                      {t('plan-trip.step3.mandatoryAttendantText')}
                    </Typography>
                  )
                }
                {planJourney![0].tripPassengers.some(x => x === PassengerTypeDto[PassengerTypeDto.Attendant] && !customer?.attendantMandatory)
                  && (
                    <Typography className="text">
                      {t('plan-trip.step3.attendantText')}
                    </Typography>
                  )
                }
                {planJourney![0].tripPassengers.some(x => x === PassengerTypeDto[PassengerTypeDto.Companion])
                  && (
                    <Typography className="text">
                      {t('plan-trip.step3.companionText')}
                    </Typography>
                  )
                }
                {planJourney![0].tripPassengers.some(x => x === PassengerTypeDto[PassengerTypeDto.Child])
                  && (
                    <Typography className="text">
                      {planJourney![0].tripPassengers.filter(x => x === PassengerTypeDto[PassengerTypeDto.Child]).length > 1 ?
                        `${planJourney![0].tripPassengers.filter(x => x === PassengerTypeDto[PassengerTypeDto.Child]).length} ${t('plan-trip.step3.childsText')}` :
                        `1 ${t('plan-trip.step3.childText')}`}
                    </Typography>
                  )
                }
              </Item>
            )}
          {planJourney![0].assistiveDevices &&
            planJourney![0].assistiveDevices.filter(x => customer?.assistiveDevices?.some(a => a.assistiveDevice.identifier === x.id && a.assistiveDevice.isPublishable)).length > 0
            && (
              <Item tabIndex={0}>
                <Typography className="label">
                  {t('plan-trip.step3.assistiveDeviceLabel')}
                </Typography>
                {customer?.assistiveDevices?.filter(x => planJourney![0].assistiveDevices?.some(a => a.id === x.assistiveDevice.identifier && x.assistiveDevice.isPublishable))
                  .map((item, index) => (
                    <Typography className="text" key={index}>
                      {item.assistiveDevice.publicName}
                    </Typography>
                  ))}
              </Item>
            )}
        </Stack>
      </div>
      <PlanTripButtons
        handleSubmit={handleSubmitForm}
      ></PlanTripButtons>
      <div aria-live="polite" className='sr-only'>
        {backScreenReaderText}
      </div>
    </div>
  );
};

export default PlanTripStep3;
