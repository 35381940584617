import { Box, Checkbox, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import i18n from 'i18next';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import addressApi from '../../../api/address';
import IconSwitch from '../../../icons/IconSwitch';
import { AddressDto, newAddressDto } from '../../../models/address';
import { selectCustomer } from '../../../store/customerSlice';
import { selectFormPlanTrip, setFormPlanTripValue } from '../../../store/planTripSlice';
import { toggleElementArray, addChild } from '../../../utils/text';
import ServerError from '../../alert/server-error/ServerError';
import AutocompleteComponent from '../../autocomplete/AutocompleteComponent';
import PlanTripButtons from '../plan-trip-buttons/PlanTripButtons';
import './PlanTripStep1.scss';
import { Debounce } from '../../utils/utils';
import TagManager from 'react-gtm-module';
import { PassengerTypeDto } from '../../../models/passengerType';
import { TripTypeChoice } from '../../../models/form-plan-trip';
import RadioBoutonGroupComponent from '../../radio/RadioBoutonGroupComponent';
import InformationAlert from '../../alert/information-alert/InformationAlert';

TagManager.dataLayer({
  dataLayer: {
    event: 'planTrip',
    step: 1,
  }
});

interface PlanTripStep1Props {
  submitClicked: boolean;
}

const PlanTripStep1 = ({ submitClicked }: PlanTripStep1Props) => {
  const [registeredAddresses, setRegisteredAddresses] = useState<AddressDto[]>([]);
  const [serviceAddresses, setServiceAddresses] = useState<AddressDto[]>([]);
  const [addresses, setAddresses] = useState<AddressDto[]>([]);
  const [isFetchRegisteredAddressesCompleted, setIsFetchRegisteredAddressesCompleted] = useState(false);
  const [isFetchServiceAddressesCompleted, setIsFetchServiceAddressesCompleted] = useState(false);
  const [optionsDepart, setOptionsDepart] = useState<AddressDto[]>([]);
  const [optionsArrival, setOptionsArrival] = useState<AddressDto[]>([]);
  const [hasTripTypeChanged, setHasTripTypeChanged] = React.useState(false);
  const [suggestedAddressesDepart, setSuggestedAddressesDepart] = useState<AddressDto[]>([]);
  const [suggestedAddressesArrival, setSuggestedAddressesArrival] = useState<AddressDto[]>([]);
  const [isLoadingAutosuggestionDepart, setIsLoadingAutosuggestionDepart] = useState<boolean>(false);
  const [isLoadingAutosuggestionArrival, setIsLoadingAutosuggestionArrival] = useState<boolean>(false);
  const [isSwitchBtnClicked, setIsSwitchBtnClicked] = useState(false);
  const [isFormInError, setIsFormInError] = useState(false);
  const [isFetchError, setIsFetchError] = useState(false);
  const [sameAddressErrorMessageRemoved, setSameAddressErrorMessageRemoved] = useState(false);
  const [departureErrorMessage, setDepartureErrorMessage] = useState<string>('');
  const [arrivalErrorMessage, setArrivalErrorMessage] = useState<string>('');
  const customer = useSelector(selectCustomer);
  const formPlanTrip = useSelector(selectFormPlanTrip);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const departureInputRef = useRef<HTMLInputElement | null>(null);
  const arrivalInputRef = useRef<HTMLInputElement | null>(null);
  const [departureInputFocusProgrammatically, setDepartureInputFocusProgrammatically] = useState<boolean | null>(null);
  const [arrivalInputFocusProgrammatically, setArrivalInputFocusProgrammatically] = useState<boolean | null>(null);
  const [departureResultFoundMessage, setDepartureResultFoundMessage] = useState<string>(''); // Use for accessibility
  const [arrivalResultFoundMessage, setArrivalResultFoundMessage] = useState<string>(''); // Use for accessibility

  useEffect(() => {
    if (departureInputFocusProgrammatically !== null && departureInputRef.current !== null) {
      departureInputRef.current.focus();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departureInputFocusProgrammatically]);

  useEffect(() => {
    if (arrivalInputFocusProgrammatically !== null && arrivalInputRef.current !== null) {
      arrivalInputRef.current.focus();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arrivalInputFocusProgrammatically]);

  useEffect(() => {
    const errorMessage = t('plan-trip.step1.addressMustBeDifferent');

    if (departureErrorMessage === errorMessage) setDepartureErrorMessage('');

    if (arrivalErrorMessage === errorMessage) setArrivalErrorMessage('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sameAddressErrorMessageRemoved]);

  useEffect(() => {
    const form = { ...formPlanTrip };
    form.isError = isFormInError;
    dispatch(setFormPlanTripValue(form));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFormInError]);

  useEffect(() => {
    if (customer?.identifier && customer?.identifier !== 0) {
      setIsFetchError(false);
      setIsLoadingAutosuggestionDepart(true);
      setIsLoadingAutosuggestionArrival(true);

      addressApi
        .getCustomerRegisteredAddresses(
          customer.identifier,
          'Null',
          `${i18n.language}-CA`
        )
        .then((response) => {
          if (response.data) {
            setRegisteredAddresses(response.data?.addresses);
            setIsFetchRegisteredAddressesCompleted(true);
          }
        })
        .catch((error) => {
          setRegisteredAddresses([]);
          setIsFetchError(true);
          setIsFetchRegisteredAddressesCompleted(true);
          console.warn(error);
        });

      addressApi
        .getCustomerServiceAddresses(
          customer.identifier,
          `${i18n.language}-CA`
        )
        .then((response) => {
          if (response.data) {
            setServiceAddresses(response.data?.addresses);
            setIsFetchServiceAddressesCompleted(true);
          }
        })
        .catch((error) => {
          setServiceAddresses([]);
          setIsFetchError(true);
          setIsFetchServiceAddressesCompleted(true);
          console.warn(error);
        });
    }
    // eslint-disable-next-line 
  }, [customer]);

  useEffect(() => {
    if (isFetchRegisteredAddressesCompleted && isFetchServiceAddressesCompleted) {
      setIsLoadingAutosuggestionDepart(false);
      setIsLoadingAutosuggestionArrival(false);

      const mergedAddresses = [...registeredAddresses, ...serviceAddresses];

      const uniqueMergedAddresses = mergedAddresses.filter(
        (item, index, self) => index === self.findIndex((x) => (
          x.addressLocation.addressIdentifier === item.addressLocation.addressIdentifier
        ))
      );

      setAddresses(uniqueMergedAddresses);
    }
    // eslint-disable-next-line 
  }, [isFetchRegisteredAddressesCompleted, isFetchServiceAddressesCompleted]);

  useEffect(() => {
    if (submitClicked) {
      const form = { ...formPlanTrip };
      addressErrorCheck(form.PointA, setDepartureErrorMessage);
      addressErrorCheck(form.PointB, setArrivalErrorMessage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitClicked]);

  const addressErrorCheck = (
    address: AddressDto | null,
    setErrorMessage: React.Dispatch<React.SetStateAction<string>>,
    eventType: string = ''
  ): boolean => {
    const form = { ...formPlanTrip };
    let errorFound = false;

    // If the entered value is null or empty, we set an error message
    if (address === null || address?.addressLocation?.shortFormattedDescription?.trim() === '') {
      setErrorMessage(t('plan-trip.step1.mandatoryAddress'));
      errorFound = true;
    } else {
      setErrorMessage('');
    }

    if (!errorFound) {
      if (address === null || address?.addressLocation.addressIdentifier === -1) {
        setErrorMessage(t('plan-trip.step1.invalidSelectionAddress'));
        errorFound = true;
      } else {
        setErrorMessage('');
      }
    }

    if (!errorFound) {
      let showError = false;

      // Check the selected address when this is triggered by change event
      if (eventType === 'change') {
        showError =
          address?.addressLocation?.shortFormattedDescription ===
          form.PointA?.addressLocation?.shortFormattedDescription ||
          address?.addressLocation?.shortFormattedDescription ===
          form.PointB?.addressLocation?.shortFormattedDescription;
      } else {
        // Otherwise, just check what selection is saved within the form
        showError =
          form.PointA?.addressLocation?.shortFormattedDescription ===
          form.PointB?.addressLocation?.shortFormattedDescription;
      }

      if (showError) {
        setErrorMessage(t('plan-trip.step1.addressMustBeDifferent'));
        errorFound = true;
      } else {
        // Since we don't know which address triggered the event, we change a flag that will trigger the removal for both
        setSameAddressErrorMessageRemoved(!sameAddressErrorMessageRemoved);
      }
    }

    setIsFormInError(errorFound);
    return !errorFound;
  };

  const handleDepartureSelected = (
    event: React.SyntheticEvent<Element, Event>,
    value: any
  ) => {
    const form = { ...formPlanTrip };
    form.PointA = value;
    dispatch(setFormPlanTripValue(form));

    addressErrorCheck(form.PointA, setDepartureErrorMessage, 'change');
  };

  const handleArrivalSelected = (
    event: React.SyntheticEvent<Element, Event>,
    value: any
  ) => {
    const form = { ...formPlanTrip };
    form.PointB = value;
    dispatch(setFormPlanTripValue(form));

    addressErrorCheck(form.PointB, setArrivalErrorMessage, 'change');
  };

  const handleDepartureTextInput = (event: React.SyntheticEvent<Element, Event>, value: string) => {
    setIsFetchError(false);

    const form = { ...formPlanTrip };
    form.PointA = newAddressDto();
    form.PointA.addressLocation.shortFormattedDescription = value;

    if (value === '' || value.length <= 3) {
      setOptionsDepart(addresses);
    }

    if (value.length >= 4 && event.type === 'change' && customer?.identifier && customer?.identifier !== 0) {
      fetchSuggestedDepartureAddresses(customer.identifier, value);
    }

    dispatch(setFormPlanTripValue(form));
  };

  const fetchSuggestedDepartureAddresses = Debounce((customerIdentifier: any, input: string) => {
    setIsLoadingAutosuggestionDepart(true);
    addressApi
      .getSuggestedAddresses(customerIdentifier, input, false, true, `${i18n.language}-CA`)
      .then((response) => {
        if (response.data) {
          setIsLoadingAutosuggestionDepart(false);

          if (response.data != null && response.data != undefined && response.data.addresses.length > 0) {
            setDepartureResultFoundMessage(t('plan-trip.step1.resultFoundMessage', { amount: response.data?.addresses.length }));
          } else {
            setDepartureResultFoundMessage(t('plan-trip.step1.noAddressFound'));
          }

          setSuggestedAddressesDepart(response.data?.addresses);
        }
      })
      .catch((error) => {
        setIsLoadingAutosuggestionDepart(false);
        setDepartureResultFoundMessage(t('plan-trip.step1.noAddressFound'));
        setSuggestedAddressesDepart([]);
        if (error.response?.status !== 404) {
          setIsFetchError(true);
        }
        if (error.response?.status === 404) {
          setOptionsDepart([]);
        }
        console.dir(error);
      });
  }, 500, 'Departure');

  const handleArrivalTextInput = (event: React.SyntheticEvent<Element, Event>, value: string) => {
    setIsFetchError(false);

    const form = { ...formPlanTrip };
    form.PointB = newAddressDto();
    form.PointB.addressLocation.shortFormattedDescription = value;

    if (value === '' || value.length <= 3) {
      setOptionsArrival(addresses);
    }

    if (value.length >= 4 && event.type === 'change' && customer?.identifier && customer?.identifier !== 0) {
      fetchSuggestedArrivalAddresses(customer.identifier, value);
    }
    dispatch(setFormPlanTripValue(form));
  };

  const fetchSuggestedArrivalAddresses = Debounce((customerIdentifier: any, input: string) => {
    setIsLoadingAutosuggestionArrival(true);
    addressApi
      .getSuggestedAddresses(customerIdentifier, input, false, true, `${i18n.language}-CA`)
      .then((response) => {
        if (response.data) {
          setIsLoadingAutosuggestionArrival(false);

          if (response.data != null && response.data != undefined && response.data.addresses.length > 0) {
            setArrivalResultFoundMessage(t('plan-trip.step1.resultFoundMessage', { amount: response.data?.addresses.length }));
          } else {
            setArrivalResultFoundMessage(t('plan-trip.step1.noAddressFound'));
          }

          setSuggestedAddressesArrival(response.data?.addresses);
        }
      })
      .catch((error) => {
        setIsLoadingAutosuggestionArrival(false);
        setArrivalResultFoundMessage(t('plan-trip.step1.noAddressFound'));
        setSuggestedAddressesArrival([]);
        if (error.response?.status !== 404) {
          setIsFetchError(true);
        }
        if (error.response?.status === 404) {
          setOptionsArrival([]);
        }
      });
  }, 500, 'Arrival');

  const handleSwitchAddresses = () => {
    // This will cause the flicker when clicking on the switch button
    setIsSwitchBtnClicked(true);
    setTimeout(() => {
      setIsSwitchBtnClicked(false);
    }, 200);

    const form = { ...formPlanTrip };
    // Update the selected value of each autocomplete
    const tempDepartureAddress = form.PointA;
    form.PointA = formPlanTrip.PointB;
    form.PointB = tempDepartureAddress;
    dispatch(setFormPlanTripValue(form));

    // Check for error after the switch
    addressErrorCheck(form.PointA, setDepartureErrorMessage);
    addressErrorCheck(form.PointB, setArrivalErrorMessage);
  };

  const handleOnBlurDeparture = () => {
    const form = { ...formPlanTrip };
    addressErrorCheck(form.PointA, setDepartureErrorMessage);
  };

  const handleOnBlurArrival = () => {
    const form = { ...formPlanTrip };
    addressErrorCheck(form.PointB, setArrivalErrorMessage);
  };

  const handleDepartureOnEscapePressed = () => {
    const form = { ...formPlanTrip };
    form.PointA = newAddressDto();

    setOptionsDepart(addresses);

    dispatch(setFormPlanTripValue(form));
  };

  const handleArrivalOnEscapePressed = () => {
    const form = { ...formPlanTrip };
    form.PointB = newAddressDto();

    setOptionsArrival(addresses);

    dispatch(setFormPlanTripValue(form));
  };

  const isFormStepValid = (): boolean => {
    const form = { ...formPlanTrip };

    // Check for error after the switch
    const isPointAValid = addressErrorCheck(form.PointA, setDepartureErrorMessage);

    let focusDone = false;

    if (!isPointAValid) {
      setDepartureInputFocusProgrammatically(!departureInputFocusProgrammatically);
      focusDone = true;
    }

    const isPointBValid = addressErrorCheck(form.PointB, setArrivalErrorMessage);

    if (!isPointBValid && !focusDone) {
      setArrivalInputFocusProgrammatically(!arrivalInputFocusProgrammatically);
    }

    return isPointAValid && isPointBValid;
  };

  const getOptionLabel = (option: AddressDto) => option.addressLocation?.shortFormattedDescription;

  function handleChangeAssistiveDevices(
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ): void {
    const form = { ...formPlanTrip };
    form.AssistiveDevices = toggleElementArray(
      form.AssistiveDevices,
      event.target.id
    );
    dispatch(setFormPlanTripValue(form));
  }

  function handleChangeAllowedPassengers(
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ): void {
    const form = { ...formPlanTrip };
    form.TripPassengers = toggleElementArray(
      form.TripPassengers,
      event.target.id
    );
    dispatch(setFormPlanTripValue(form));
  }

  function handleChangeNumberOfChildPassengers(
    event: ChangeEvent<HTMLInputElement>,
  ): void {
    const form = { ...formPlanTrip };
    form.TripPassengers = addChild(
      form.TripPassengers.filter(x => x !== PassengerTypeDto[PassengerTypeDto.Child]),
      +event.target.value
    );
    dispatch(setFormPlanTripValue(form));
  }
  const handleOnChangeTripType = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    const form = { ...formPlanTrip };
    form.TripType = value as TripTypeChoice;
    dispatch(setFormPlanTripValue(form));
    setHasTripTypeChanged(!hasTripTypeChanged);
  };


  useEffect(() => {
    if (suggestedAddressesDepart.length > 0) {
      setOptionsDepart(suggestedAddressesDepart);
    }

    if (suggestedAddressesArrival.length > 0) {
      setOptionsArrival(suggestedAddressesArrival);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suggestedAddressesDepart, suggestedAddressesArrival]);

  useEffect(() => {
    if (addresses) {
      if (suggestedAddressesDepart.length === 0) {
        setOptionsDepart(addresses);
      }
      if (suggestedAddressesArrival.length === 0) {
        setOptionsArrival(addresses);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addresses]);

  return (
    <div>
      <Typography variant="h1" component="h1" className="PlanTripTitle" mb={4}>
        {t('plan-trip.step1.title')}
      </Typography>
      <Box mb={4}>
        <FormControl className="radio-group-container field">
          <RadioBoutonGroupComponent
            defaultValue={TripTypeChoice.RoundTrip}
            valuesList={[TripTypeChoice.RoundTrip, TripTypeChoice.OneWay]}
            labelsList={[t('plan-trip.step1.optinRoundTrip'), t('plan-trip.step1.optinOneWay')]}
            onChange={handleOnChangeTripType}
            value={formPlanTrip.TripType}
          />
        </FormControl>
      </Box>
      {isFetchError && <ServerError>{t('error.addressErrorText')}</ServerError>}
      <FormControl className="container">
        <AutocompleteComponent
          isError={!!departureErrorMessage.trim()}
          errorMessage={departureErrorMessage}
          label={t('plan-trip.step1.departureAddress')}
          labelIconLetter="A"
          value={formPlanTrip.PointA?.addressLocation?.addressIdentifier !== -1 ? formPlanTrip.PointA : null}
          inputValue={
            formPlanTrip.PointA !== null ? formPlanTrip.PointA?.addressLocation?.shortFormattedDescription : ''
          }
          inputPlaceholderText={t('plan-trip.step1.departureInputPlaceholderText')}
          inputRef={departureInputRef}
          inputFocusProgrammatically={departureInputFocusProgrammatically}
          options={optionsDepart}
          isLoading={isLoadingAutosuggestionDepart}
          getOptionLabel={getOptionLabel}
          setOptionSelected={handleDepartureSelected}
          setInputValue={(event: any, value: string) => { handleDepartureTextInput(event, value); }}
          onBlur={handleOnBlurDeparture}
          onEscapePressed={handleDepartureOnEscapePressed}
          noOptionsText={t('plan-trip.step1.noAddressFound')}
          ariaLabelPopupIndicatorOpen={t('plan-trip.step1.ariaLabelPopupIndicatorOpen')}
          ariaLabelPopupIndicatorClose={t('plan-trip.step1.ariaLabelPopupIndicatorClose')}
          resultFoundMessage={departureResultFoundMessage}
          clearText={t('plan-trip.step1.clearText')}
        />
      </FormControl>
      <div className="iconButton swapButton">
        <IconButton
          className={isSwitchBtnClicked ? 'flicker' : ''}
          onClick={handleSwitchAddresses}
          aria-label={t('plan-trip.step1.invertButtonLabel')}
        >
          <IconSwitch />
        </IconButton>
      </div>
      <FormControl className="container">
        <AutocompleteComponent
          isError={!!arrivalErrorMessage.trim()}
          errorMessage={arrivalErrorMessage}
          label={t('plan-trip.step1.arrivalAddress')}
          labelIconLetter="B"
          value={ formPlanTrip.PointB?.addressLocation?.addressIdentifier !== -1 ? formPlanTrip.PointB : null }
          inputValue={
            formPlanTrip.PointB !== null && formPlanTrip.PointB.addressLocation?.shortFormattedDescription
              ? formPlanTrip.PointB?.addressLocation?.shortFormattedDescription
              : ''
          }
          inputPlaceholderText={t('plan-trip.step1.arrivalInputPlaceholderText')}
          inputRef={arrivalInputRef}
          inputFocusProgrammatically={arrivalInputFocusProgrammatically}
          options={optionsArrival}
          isLoading={isLoadingAutosuggestionArrival}
          getOptionLabel={getOptionLabel}
          setOptionSelected={handleArrivalSelected}
          setInputValue={(event: React.SyntheticEvent<Element, Event>, value: string) => handleArrivalTextInput(event, value)}
          onBlur={handleOnBlurArrival}
          onEscapePressed={handleArrivalOnEscapePressed}
          noOptionsText={t('plan-trip.step1.noAddressFound')}
          ariaLabelPopupIndicatorOpen={t('plan-trip.step1.ariaLabelPopupIndicatorOpen')}
          ariaLabelPopupIndicatorClose={t('plan-trip.step1.ariaLabelPopupIndicatorClose')}
          resultFoundMessage={arrivalResultFoundMessage}
          clearText={t('plan-trip.step1.clearText')}
        />
      </FormControl>
      {customer?.allowedPassengers?.some(x => x === PassengerTypeDto[PassengerTypeDto.Child]
        || x === PassengerTypeDto[PassengerTypeDto.Companion] || x === PassengerTypeDto[PassengerTypeDto.Attendant]) && (
          <div>
            <div className="attendantLabel">
              {t('plan-trip.step1.attendantLabel')}
            </div>

            {customer?.attendantMandatory && (
              <div className="attendantText">
                {t('plan-trip.step1.attendantText')}
              </div>
            )}

            <RadioGroup name='allowedPassengersRadioGroup'>
              {customer?.allowedPassengers?.some(x => x === PassengerTypeDto[PassengerTypeDto.Companion]) && (
                <>
                  {formPlanTrip.TripType === TripTypeChoice.RoundTrip
                    && (<InformationAlert>{t('plan-trip.step1.attendantRoundTripText')}</InformationAlert>)}
                    {formPlanTrip.TripType === TripTypeChoice.OneWay
                    && (<InformationAlert>{t('plan-trip.step1.attendantOneWayTripText')}</InformationAlert>)}
                  <div className='margin-top'>
                    <FormControlLabel
                      key={PassengerTypeDto.Companion}
                      value={PassengerTypeDto.Companion}
                      label={t('plan-trip.step1.companionOptionalText')}
                      id={PassengerTypeDto[PassengerTypeDto.Companion]}
                      checked={formPlanTrip.TripPassengers.includes(
                        PassengerTypeDto[PassengerTypeDto.Companion]
                      )}
                      control={
                        <Checkbox
                          name={PassengerTypeDto[PassengerTypeDto.Companion]}
                          onChange={handleChangeAllowedPassengers}
                          id={PassengerTypeDto[PassengerTypeDto.Companion]}
                        />
                      }
                    ></FormControlLabel>
                  </div>
                </>

              )}

              {customer?.allowedPassengers?.some(x => x === PassengerTypeDto[PassengerTypeDto.Attendant]) && !customer?.attendantMandatory && (
                <>
                  {formPlanTrip.TripType === TripTypeChoice.RoundTrip
                    && (<InformationAlert>{t('plan-trip.step1.attendantRoundTripText')}</InformationAlert>)}
                  {formPlanTrip.TripType === TripTypeChoice.OneWay
                  && (<InformationAlert>{t('plan-trip.step1.attendantOneWayTripText')}</InformationAlert>)}
                  <div className='margin-top'>
                    <FormControlLabel
                      key={PassengerTypeDto.Attendant}
                      value={PassengerTypeDto.Attendant}
                      label={t('plan-trip.step1.attendantOptionalText')}
                      id={PassengerTypeDto[PassengerTypeDto.Attendant]}
                      checked={formPlanTrip.TripPassengers.includes(
                        PassengerTypeDto[PassengerTypeDto.Attendant]
                      )}
                      control={
                        <Checkbox
                          name={PassengerTypeDto[PassengerTypeDto.Attendant]}
                          onChange={handleChangeAllowedPassengers}
                          id={PassengerTypeDto[PassengerTypeDto.Attendant]}
                        />
                      }
                    ></FormControlLabel>
                  </div>
                </>

              )}

              {customer?.allowedPassengers?.some(x => x === PassengerTypeDto[PassengerTypeDto.Child]) && (
                <FormControlLabel
                  key={PassengerTypeDto.Child}
                  value={PassengerTypeDto.Child}
                  label={t('plan-trip.step1.companionChildText')}
                  id={PassengerTypeDto[PassengerTypeDto.Child]}
                  checked={formPlanTrip.TripPassengers.includes(
                    PassengerTypeDto[PassengerTypeDto.Child]
                  )}
                  control={
                    <Checkbox
                      name={PassengerTypeDto[PassengerTypeDto.Child]}
                      onChange={handleChangeAllowedPassengers}
                      id={PassengerTypeDto[PassengerTypeDto.Child]}
                    />
                  }
                ></FormControlLabel>
              )}
            </RadioGroup>
            {formPlanTrip.TripPassengers.some(x => x === PassengerTypeDto[PassengerTypeDto.Child]) &&
              (
                <div>
                  <div className="numberOfChildAllowedLabel">
                    {t('plan-trip.step1.numberOfChildAllowedLabel')}
                  </div>
                  <RadioGroup name='numberOfChildAllowedRadioGroup'
                    defaultValue={1}
                    row>
                    {customer?.allowedPassengers?.filter(x => x === PassengerTypeDto[PassengerTypeDto.Child]).map((item, index) => (
                      <FormControlLabel
                        key={index + 1}
                        value={index + 1}
                        label={index + 1}
                        id={item + index}
                        checked={(index + 1) === formPlanTrip.TripPassengers.filter(x => x === PassengerTypeDto[PassengerTypeDto.Child]).length}
                        control={
                          <Radio
                            onChange={handleChangeNumberOfChildPassengers}
                            id={(index + 1) + ''}
                          />
                        }
                      ></FormControlLabel>
                    ))}
                  </RadioGroup>
                </div>
              )}
          </div>
      )}

      {(customer?.assistiveDevices?.filter(x => x.assistiveDevice.isPublishable).length ?? 0) > 0 && (
        <div>
          <div className="assistiveDeviceLabel">
            {t('plan-trip.step1.assistiveDeviceLabel')}
          </div>
          <RadioGroup name='assistedDeviceRadioGroup'>
            {customer?.assistiveDevices?.filter(x => x.assistiveDevice.isPublishable).map((item, index) => (
              <FormControlLabel
                key={index}
                value={item?.assistiveDevice.identifier}
                checked={formPlanTrip.AssistiveDevices.includes(
                  item.assistiveDevice.identifier
                )}
                // onChange={handleChangeAssistiveDevices}
                className="assistiveDeviceText"
                label={item.assistiveDevice.publicName}
                id={item.assistiveDevice.identifier}
                control={
                  <Checkbox
                    name={item.assistiveDevice.identifier}
                    onChange={handleChangeAssistiveDevices}
                    id={item.assistiveDevice.identifier}
                  />
                }
              ></FormControlLabel>
            ))}
          </RadioGroup>
        </div>
      )}

      <PlanTripButtons
        handleSubmit={isFormStepValid}
      ></PlanTripButtons>
    </div>
  );
};

export default PlanTripStep1;
