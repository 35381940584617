import React from 'react';
import { useTranslation } from 'react-i18next';
import { JourneyAddressDto, RequestedTimeType } from '../../../../models/journey';
import './TripDetailsRoute.scss';
import  dayjs from 'dayjs';

interface TripDetailsUnconfirmedRouteProps {
  title: string,
  pickUp: JourneyAddressDto,
  dropOff: JourneyAddressDto,
  requestedTimeType?: RequestedTimeType,
  requestTime: string,
}

const TripDetailsUnconfirmedRoute = ({
  title,
  pickUp,
  dropOff,
  requestedTimeType,
  requestTime
}: TripDetailsUnconfirmedRouteProps) => {
  const { t } = useTranslation();

  const formatTime = (date: string) => {
    return dayjs(date).format(t('trips.times.format'));
  };

  return (
    <div className="TripDetailsRoute">
      <h3 className="TripDetailsRouteTitle">{title}</h3>
      <div className="TripDetailsRouteItem">
        <div className="TripDetailsRouteItemDesc">
          <span>
            <strong>{t('trips.route.From')}</strong>
            {pickUp?.longFormattedDesc}
          </span>
        </div>
      </div>
      <div className="TripDetailsRouteItem">
        <div className="TripDetailsRouteItemDesc">
          <span>
            <strong>{t('trips.route.To')}</strong>
            {dropOff?.longFormattedDesc}
          </span>
        </div>
      </div>
      <div className="TripDetailsRouteTimes">
        <div>
          <h3 className="TripDetailsRouteTimesTitle">{t('trips.times.pickup')}</h3>
          <span className="TripDetailsUnconfirmedRouteTimesValue">{t('trips.times.waitingConfirmation')}</span>
        </div>
        <div>
          <h3 className="TripDetailsRouteTimesTitle">{t(`trips.times.askedType.${requestedTimeType}`)}</h3>
          <span className="TripDetailsRouteTimesValue">{formatTime(requestTime)}</span>
        </div>
      </div>
    </div>
  );
};

export default TripDetailsUnconfirmedRoute;