import { PassengerTypeDto } from '../models/passengerType';

export const capitalize = (str: string): string => {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
};
export const toggleElementArray = (arr: Array<string>, val: string) =>
  arr.includes(val) ? arr.filter((el) => el !== val) : [...arr, val];

export const addChild = (arr: Array<string>, iteration: number) =>{
  for (let i = 0; i < iteration; i++ ) {
    arr = [...arr, PassengerTypeDto[PassengerTypeDto.Child] ];
  }

  return arr;
};
