import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import './TripRoundDateTime.scss';
import { Divider, Grid } from '@mui/material';
import { RequestedTimeType } from '../../../../models/journey';


export interface TripRoundUnconfirmedProps {
  requestTypeTrip?: RequestedTimeType,
  requestTimeTrip: string,
  requestTypeReturnTrip?: RequestedTimeType,
  requestTimeReturnTrip: string
}

const TripRoundUnconfirmedDateTime = ({
  requestTypeTrip,
  requestTimeTrip,
  requestTypeReturnTrip,
  requestTimeReturnTrip
}: TripRoundUnconfirmedProps) => {
  const { t } = useTranslation();

  const formatTime = (date: string) => {
    return dayjs(date).format(t('trips.times.format'));
  };

  return (
        <div className="TripRoundTimes">
            <Grid container spacing={0} className='flex-nowrap'>
                <Grid item>
                    <Grid container spacing={0} direction={'column'}>
                        <Grid item>                       
                            <h4 className="TripTimesTitle">{t('trips.times.tripStart')}</h4>
                        </Grid>                      
                        <Grid item>
                            <h4 className="TripTimesValue">{t(`trips.times.askedType.${requestTypeTrip}`)}</h4>
                        </Grid>
                        <Grid item>
                            <span className="TripTimesTitle">{formatTime(requestTimeTrip)}</span>
                        </Grid>
                    </Grid>
                </Grid>
                <Divider orientation="vertical" variant="middle" flexItem className='dividerVertical' />
                <Grid item>
                    <Grid container spacing={0} direction={'column'}>
                        <Grid item>
                            <h4 className="TripTimesTitle">{t('trips.times.tripEnd')}</h4>
                        </Grid>                       
                        <Grid item>
                            <h4 className="TripTimesValue">{t(`trips.times.askedType.${requestTypeReturnTrip}`)}</h4>
                        </Grid>
                        <Grid item>
                            <span className="TripTimesTitle">{formatTime(requestTimeReturnTrip)}</span>
                        </Grid>                       
                    </Grid>                   
                </Grid>
            </Grid>         
        </div>
  );
};

export default TripRoundUnconfirmedDateTime;